export const faqView = {
    event: "c2c_faq",
    eventCategory: "Buy_used_cars_B2C_Car_Listing_Page",
    eventAction: "faq_view"
};

export const onClickFAQ = {
    event: "c2c_faq",
    eventCategory: "Buy_used_cars_B2C_Car_Listing_Page",
    eventAction: "faq_click"
};
