import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_TESTIMONIAL_DATA
        FETCH_TESTIMONIAL_DATA_SUCCESS
        FETCH_TESTIMONIAL_DATA_FAILURE
    `,
    {
        prefix: "testimonials/"
    }
);
