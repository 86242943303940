import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "./component";

const mapStateToProps = ({
    footer
}) => ({
    footer
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
