import React, { useEffect, useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import H2 from "../../shared/h2/component";
import ArrowIcon from "../../shared/arrow";
import { getCombinedValue, getFullName } from "../../../utils/helpers/seo-car-config";
import { NUMBER } from "../../../constants/app-constants";

const CarMileageSeo = ({
    pageData,
    onPageRedirection
}) => {
    const { make, model, overview } = pageData;
    const carFullName = getFullName(make, model);
    const { mileage } = overview;
    const {
        Petrol: mileagePetrol = [],
        Diesel: mileageDiesel = [],
        CNG: mileageCNG = [],
        content: mileageContent
    } = mileage;
    const [combinedMileage, setCombinedMileage] = useState([]);
    useEffect(() => {
        setCombinedMileage(getCombinedValue(mileagePetrol, mileageDiesel, mileageCNG));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mileage]);
    return (
        <React.Fragment>
            {combinedMileage && combinedMileage.length > 0 &&
                <div styleName={"styles.sectionWrapper"}>
                    <div className="container">
                        <H2 text={`${carFullName} MILEAGE`} />
                        <p styleName={"styles.subTitleText"}>{mileageContent}</p>
                    </div>
                    <div styleName={"styles.tableHeaderStrip"}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-xs-12">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Variants</td>
                                                <td>Mileage</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {combinedMileage.length > NUMBER.TWO && <div className="col-md-6 col-xs-12">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Variants</td>
                                                <td>Mileage</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row mb-4">
                            {combinedMileage.map((mileages, index) => (
                                <div className="col-md-6" key={index}>
                                    <p styleName={"styles.tableView"}>{mileages.transmission_type}, {mileages.fuel_type}<span>{mileages.arai_mileage} kmpl</span></p>
                                </div>
                            ))}
                        </div>
                        <a href={onPageRedirection("mileage")} styleName={"styles.ctaText"} onClick={(event) => onPageRedirection("mileage", true, event)}>View {model} Details <ArrowIcon /> </a>
                    </div>
                </div>}
        </React.Fragment>
    );
};

CarMileageSeo.propTypes = {
    pageData: PropTypes.object,
    onPageRedirection: PropTypes.func
};

export default CarMileageSeo;
