import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_DATA
    FETCH_DATA_SUCCESS
    FETCH_DATA_FAILURE
    UPDATE_API_STATUS
    UPDATE_WHATSAPP_STATUS
`,
    {
        prefix: "leadkilometerdriven/"
    }
);
