const BUDGET_IDENTIFIER = {
    UNDER: "under",
    ABOVE: "above",
    BETWEEN: "between"
};

const BUDGET_KEY_MAP = {
    0: BUDGET_IDENTIFIER.UNDER,
    99: BUDGET_IDENTIFIER.ABOVE,
    default: BUDGET_IDENTIFIER.BETWEEN
};

const BUDGET_UNITS = "lakhs";

const getKeywordForPrice = (prices) => {
    let key;
    if (BUDGET_KEY_MAP[prices[0]]) key = BUDGET_KEY_MAP[prices[0]];
    else if (BUDGET_KEY_MAP[prices[1]]) key = BUDGET_KEY_MAP[prices[1]];
    else key = BUDGET_KEY_MAP.default;
    return key;
};

export const computeBudgetURL = ({ value }) => {
    const url = ["cars"];
    const prices = value.split("-").map(Number);
    const keyword = getKeywordForPrice(prices);
    if (keyword === BUDGET_IDENTIFIER.UNDER) {
        url.push(BUDGET_IDENTIFIER.UNDER, prices[1]);
    } else if (keyword === BUDGET_IDENTIFIER.ABOVE) {
        url.push(BUDGET_IDENTIFIER.ABOVE, prices[0]);
    } else {
        url.push(BUDGET_IDENTIFIER.BETWEEN, prices[0], "to", prices[1]);
    }
    url.push(BUDGET_UNITS);
    return url;
};
