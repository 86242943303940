import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import H2 from "../../shared/h2/component";
import ArrowIcon from "../../shared/arrow";
import { getFullName } from "../../../utils/helpers/seo-car-config";
import OverviewDescription from "../../shared/overview-description";

const CarImagesSeo = ({
    pageData,
    onPageRedirection
}) => {
    const { make, model, overview, mediaConfigUrl, modelImageMobile } = pageData;
    const  {images: {content: imageContent} }  = overview;
    const carFullName = getFullName(make, model);

    return (
        <div styleName={"styles.sectionWrapper"}>
            <div className="container">
                <H2 text={`${carFullName} IMAGES`} />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <div styleName={"styles.carImage"}>
                            <img src={`${mediaConfigUrl}${modelImageMobile}`} alt={`${carFullName} - Front Side`} />
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        {/* <p styleName={"styles.contentText"}>
                            {imageContent}
                        </p> */}
                        <OverviewDescription data={imageContent} />
                        <a href={onPageRedirection("images")} onClick={(event) => onPageRedirection("images", true, event)} styleName={"styles.ctaText"}>VIEW ALL {model} IMAGES <ArrowIcon /> </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

CarImagesSeo.propTypes = {
    pageData: PropTypes.string,
    onPageRedirection: PropTypes.func
};

export default CarImagesSeo;

