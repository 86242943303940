/* eslint-disable max-params */

import { NUMBER } from "../../constants/app-constants";

export const getCombinedValue = (petrol = [], diesel = [], cng = [], isSplice = true) => {
    const pertolValue = [...petrol];
    const desiglValue = [...diesel];
    const cngValue = [...cng];
    const combinedArray = [
        ...(pertolValue && isSplice ? pertolValue.splice(0, NUMBER.TWO) : pertolValue),
        ...(desiglValue && isSplice ? desiglValue.splice(0, NUMBER.TWO) : desiglValue),
        ...(cngValue && isSplice ? cngValue.splice(0, NUMBER.TWO) : cngValue)
    ];
    return combinedArray;
};

export const getSeoCarName = (url) => {
    return url.replace(/^\/|\/$/g, "");
};

export const getFullName = (make, model) => {
    return `${make} ${model}`;
};
export const removeSlash = (seoCarName) => {
    return seoCarName.replaceAll("-", " ").replaceAll("/", " ");
};

export const getBuyUsedCarUrl = (payload, seoCarName) => {
    const carName = removeSlash(seoCarName);
    const url = `buy-used-car?storeCityId=${payload}&carName=${carName}`;
    return url;
};

export const getMaxPrice = (price, property) => {
    const maxPrice = price.reduce((max, b) => Math.max(max, b[property]), price[0][property]);
    return maxPrice;
};

export const getMinPrice = (price,  property) => {
    const minPrice = price.reduce((min, b) => Math.min(min, b[property]), price[0][property]);
    return minPrice;
};
