import React from "react";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router";
import styles from "./styles.css";
import catImage from "./image/carError.png";
import ErrorImage from "./image/404.svg";
import Button from "../button";
import PropTypes from "prop-types";

const PageNotFound = ({ history }) => {
    const redirectToHome = () => {
        history.replace("/");
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Page Not Found</title>
                <meta name="ROBOTS" content="NOINDEX,FOLLOW" />
            </Helmet>
            <div styleName={"styles.notFound"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12 text-center"}>
                            <div styleName={"styles.notFoundGraphic"}>
                                <img
                                    src={catImage} alt=""
                                    styleName={"styles.animated styles.swing"}
                                />
                                <img src={ErrorImage} alt="" styleName={"styles.errorImage"} />
                            </div>
                            <h4 styleName={"styles.oops"}>OOPS!</h4>
                            <p>
                                We couldn't find the page you were looking for. <br />
                                Don't panic or you'll wake up the cat. Press the button below to
                                go to homepage.
                            </p>
                            <Button
                                text={"Go to homepage"}
                                onClick={redirectToHome}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

PageNotFound.propTypes = {
    history: PropTypes.object.isRequired
};

export default withRouter(PageNotFound);
