export const Node = ({
    name,
    component,
    visited,
    attributes = {}
}) => {
    return {
        name,
        component,
        visited,
        attributes
    };
};
