import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    dataList: [],
    heading: "",
    insMediaServer: "",
    error: null
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, ...data, error: null };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [] };
};

export const HANDLERS = {
    [Types.FETCH_RECENTLY_INSPECTED_DATA_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_RECENTLY_INSPECTED_DATA_FAILURE]: fetchDataFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
