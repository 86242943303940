import React, { useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import H2 from "../../shared/h2/component";
import { getFullName } from "../../../utils/helpers/seo-car-config";
import OverviewDescription from "../../shared/overview-description";

const TABS = {
    PROS: "pros",
    CONS: "cons"
};

const CarProsConsSeo = ({
    pageData
}) => {
    const { make, model, overview } = pageData;
    const {
        overview: { pros, cons }
    } = overview;
    const [activeTab, setActiveTab] = useState(TABS.PROS);
    const carFullName = getFullName(make, model);

    const changeTabs = (active) => {
        if (active === TABS.PROS) {
            setActiveTab(active);
        } else {
            setActiveTab(active);
        }
    };
    return (
        <React.Fragment>
            {(pros || cons) && <div styleName={"styles.sectionWrapper"}>
                <div className="container">
                    <H2 text={`${carFullName} PRO’s & CON’s`} />
                </div>
                <div styleName={"styles.tableHeaderStrip"}>
                    <div className="container">
                        {(pros && cons) ? <ul styleName={"styles.tabNavs"}>
                            <li styleName={activeTab === TABS.PROS ? "styles.active" : ""} onClick={() => changeTabs(TABS.PROS)}>
                                Things We Like
                            </li>
                            <li styleName={activeTab === TABS.CONS ? "styles.active" : ""} onClick={() => changeTabs(TABS.CONS)}>
                                Things we don’t like
                            </li>
                        </ul> : <h3 styleName={"styles.singleTitle"}>{pros ? "Things We Like" : "Things we don’t like"} </h3>
                        }
                    </div>
                </div>
                <div className="container">
                    <OverviewDescription data={pros} isActive={activeTab === TABS.PROS} />
                    <OverviewDescription data={cons} isActive={activeTab === TABS.CONS} />
                </div>
            </div>}
        </React.Fragment>
    );
};

CarProsConsSeo.propTypes = {
    pageData: PropTypes.object
};

export default CarProsConsSeo;
