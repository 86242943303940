/* eslint-disable max-statements */
/* eslint-disable camelcase */
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import { STEP_NAMES, NUMBER } from "../../../constants/app-constants";
import { TABS, TAB_DATA } from "../../../utils/funnel/steps.desktop";
import parsePriceRanges from "../../../utils/parsers/parse-price-ranges";
import { findStep } from "../../../utils/funnel/stepper";
import { KM_RANGE } from "../../../constants/seller-constant";

const isDataChanged = (before, after) => {
    return before !== after;
};

// eslint-disable-next-line consistent-return
const getKmsRange = (kilmeterDriven) => {
    const isKmsAvailable = KM_RANGE.filter(kms =>  parseInt(kms.value)  === parseInt(kilmeterDriven));
    if (isKmsAvailable && isKmsAvailable.length) return isKmsAvailable[0].label;
};

const getStepindex = (currentStep, tabs = TABS) => {
    const currentTab = tabs.filter(tab => {
        if (currentStep === "kilometersDriven") {
            return tab.name === "KilometerDriven";
        } else {
            return currentStep.toLowerCase().includes(tab.name.toLowerCase());
        }
    }
        // currentStep === "kilometersDriven" ? tab.name === "KilometerDriven" : currentStep.toLowerCase().includes(tab.name.toLowerCase())
    );
    return currentTab[0].index;
};

const resetTabValues = (currentStepIndex, tabData = TAB_DATA, registrationNo) => {
    const tabList = Object.keys(tabData);
    const precedingValues = {};
    for (let index = currentStepIndex; index <= tabList.length; index++) {
        if (tabList[index - 1] === STEP_NAMES.Variant) {
            precedingValues[tabList[index - 1]] = { displayText: "", id: null, variantType: null };
        } else if (tabList[index - 1] === STEP_NAMES.Year) {
            precedingValues[tabList[index - 1]] = { displayText: "", id: null, year_id: null };
        } else if (tabList[index - 1] === STEP_NAMES.Branch) {
            precedingValues[tabList[index - 1]] = {
                displayText: "",
                id: null,
                selectionType: "city"
            };
        } if (tabList[index - 1] === STEP_NAMES.State) {
            if (registrationNo && (!registrationNo.userCarRegistrationNo || registrationNo.isError)) {
                precedingValues[tabList[index - 1]] = { displayText: "", id: null };
            }
        } else {
            precedingValues[tabList[index - 1]] = { displayText: "", id: null };

            if (currentStepIndex < NUMBER.FIVE) {
                precedingValues[STEP_NAMES.FuelType] = { displayText: "" };
                precedingValues[STEP_NAMES.Transmission] = { displayText: "" };
                precedingValues.variantActiveScreen = "fueltype";
                precedingValues.mobile = { displayText: "", id: null };
                precedingValues.allStepCompleted = null;
            }
        }
    }
    const activateLastStep = null;
    return { ...precedingValues, activateLastStep };
};

const updateLeadFormState = ({
    state,
    previousStep,
    value,
    nextStep,
    tabs,
    tabData,
    registrationNo
}) => {

    let currentStepIndex = state.currentStepIndex;
    let currentProgressStep = state.currentProgressStep;
    let resettedTabValues = {};
    const dataChanged = isDataChanged(state[previousStep].id, value.id);
    // const regNo = registrationNo && registrationNo.userCarRegistrationNo;
    let proceedCTA = state.proceedCTA;
    if (dataChanged) {
        currentProgressStep = previousStep;
        proceedCTA = false;
        currentStepIndex = getStepindex(nextStep, tabs);
        resettedTabValues = resetTabValues(currentStepIndex, tabData, registrationNo);
    }
    return {
        ...state,
        [previousStep]: value,
        ...resettedTabValues,
        currentStepIndex,
        proceedCTA,
        currentProgressStep,
        activateFirstStep: false,
        isLeadFormChanged: dataChanged
    };
};

export const INITIAL_STATE = {
    brand: { displayText: "", id: null },
    model: { displayText: "", id: null },
    year: { displayText: "", id: null, year_id: null },
    fuelType: { displayText: "" },
    transmission: { displayText: "" },
    registrationState: { displayText: "", id: null },
    variant: { displayText: "", id: null, variantType: null },
    kilometersDriven: { displayText: "", id: null },
    rto: { displayText: "", id: null },
    branch: { displayText: "", id: null, selectionType: "city" },
    currentStep: STEP_NAMES.Brand,
    currentProgressStep: "",
    currentStepIndex: getStepindex(STEP_NAMES.Brand),
    currentTabIndex: getStepindex(STEP_NAMES.Brand),
    prevTabIndex: 0,
    proceedCTA: false,
    token: null,
    error: null,
    activateLastStep: false,
    priceRange: {},
    source: null,
    isLeadFormChanged: false,
    flowName: "default",
    ctaLocation: null,
    variantActiveScreen: "fueltype",
    mobile: { displayText: "", id: null },
    allStepCompleted: false,
    leadScore: null,
    activateFirstStep: true,
    askUserIntent: false,
    userIntent: "",
    ownerName: "",
    carRegistrationNo: "",
    isAllStepFill: false,
    showWidget: false
};

export const setBrand = (state = INITIAL_STATE, { brand, stepper, tabs, tabData, registrationNo }) => {
    const currentNode = findStep({ list: stepper, stepName: STEP_NAMES.Brand });
    return updateLeadFormState({
        state,
        previousStep: STEP_NAMES.Brand,
        value: brand,
        nextStep: currentNode.next.value.name,
        tabs,
        tabData,
        registrationNo
    });
};

export const setModel = (state = INITIAL_STATE, { model, stepper, tabs, tabData, registrationNo }) => {
    const currentNode = findStep({ list: stepper, stepName: STEP_NAMES.Model });
    return updateLeadFormState({
        state,
        previousStep: STEP_NAMES.Model,
        value: model,
        nextStep: currentNode.next.value.name,
        tabs,
        tabData,
        registrationNo
    });
};

export const setYear = (state = INITIAL_STATE, { year, stepper, tabs, tabData, registrationNo }) => {
    const currentNode = findStep({ list: stepper, stepName: STEP_NAMES.Year });
    return updateLeadFormState({
        state,
        previousStep: STEP_NAMES.Year,
        value: year,
        nextStep: currentNode && currentNode.next ? currentNode.next.value.name : STEP_NAMES.Variant,
        tabs,
        tabData,
        registrationNo
    });
};

export const setVariant = (state = INITIAL_STATE, { variant, registrationNo }) => {
    return updateLeadFormState({
        state,
        previousStep: STEP_NAMES.Variant,
        value: variant,
        nextStep: STEP_NAMES.KilometerDriven,
        registrationNo
    });
};

export const setBranch = (state = INITIAL_STATE, { branch, tabs, stepper, tabData }) => {
    const currentNode = findStep({ list: stepper, stepName: STEP_NAMES.Branch });
    return updateLeadFormState({
        state,
        previousStep: STEP_NAMES.Branch,
        value: branch,
        nextStep: currentNode.next.value.name,
        tabs,
        tabData
    });
};

export const setKilometerDriven = (state = INITIAL_STATE, { data }) => {
    const kmsData = {
        displayText: data.displayText,
        id: data.id
    };
    return {
        ...state,
        ...(data.isRevamped && {
            currentStepIndex: NUMBER.SEVEN,
            currentTabIndex: NUMBER.SEVEN
        }),
        kilometersDriven: kmsData,
        isLeadFormChanged: isDataChanged(state.kilometersDriven.id, data.id)
    };
};

export const setRegistrationState = (state = INITIAL_STATE, { registrationState }) => {
    let { currentStepIndex, currentProgressStep, prevTabIndex, currentTabIndex } = state;
    let resettedTabValues = {};
    const dataChanged = isDataChanged(state.registrationState.id, registrationState.id);
    if (dataChanged || (!dataChanged && state.mobile && state.mobile.displayText)) {
        currentProgressStep = STEP_NAMES.State;
        currentStepIndex = NUMBER.SIX;
        resettedTabValues = resetTabValues(currentStepIndex);
    }
    prevTabIndex = currentTabIndex;
    currentTabIndex = NUMBER.SIX;
    return {
        ...state,
        registrationState,
        currentProgressStep,
        currentStepIndex,
        prevTabIndex,
        ...resettedTabValues
    };
};

export const setStep = (state = INITIAL_STATE, { currentStep, tabs }) => {
    const prevTabIndex = state.currentTabIndex;
    const currentTabIndex = getStepindex(currentStep, tabs);
    return { ...state, currentStep, currentTabIndex, prevTabIndex };
};

export const makeLeadSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        token: data.token,
        priceRange: parsePriceRanges(data),
        error: null
    };
};

export const makeLeadFailure = (state = INITIAL_STATE, { data }) => {
    return { ...state, token: null, error: data };
};

export const showProceedButton = (state = INITIAL_STATE) => {
    return { ...state, proceedCTA: true };
};

export const activateLastStep = (state = INITIAL_STATE, { data }) => {
    return { ...state, activateLastStep: data };
};

export const activateFirstStep = (state = INITIAL_STATE, { data }) => {
    return { ...state, activateFirstStep: data };
};

export const setAllStepComplete = (state = INITIAL_STATE, { data }) => {
    return { ...state, allStepCompleted: data };
};

export const setLeadScore = (state = INITIAL_STATE, { leadScore }) => {
    return { ...state, leadScore };
};

export const mergeLeadData = (state = INITIAL_STATE, { data }) => {
    const {
        token,
        make_id, make_display,
        model_id, model_display,
        year, year_id,
        variant_id, variant_display_name,
        state_id, state_code,
        kms, kms_range,
        rto_id,
        rto_code = "NA",
        lead_type,
        whatsapp_consent
    } = data;

    return {
        ...state,
        brand: { id: make_id, displayText: make_display },
        model: { id: model_id, displayText: model_display },
        year: { id: year, displayText: year.toString(), year_id },
        variant: { id: variant_id, displayText: variant_display_name },
        registrationState: { id: state_id, displayText: state_code },
        priceRange: parsePriceRanges(data),
        kilometersDriven: { id: kms, displayText: kms_range || getKmsRange(kms) },
        token,
        currentStepIndex: 7,
        prevTabIndex: 5,
        source: lead_type,
        currentStep: STEP_NAMES.KilometerDriven,
        allStepCompleted: true,
        whatsappConsent: whatsapp_consent,
        ...(rto_id && { rto: { displayText: rto_code, id: rto_id } })
    };
};

export const resetLeadFormData = (state = INITIAL_STATE, {clearToken}) => {
    const { token, showWidget } = state || {};
    return { ...state, ...INITIAL_STATE, showWidget,
        ...(clearToken && {token, activateFirstStep: false})
    };
};

export const setFlowName = (state = INITIAL_STATE, { flowName }) => {
    return { ...state, flowName };
};

export const updateCTALocation = (state = INITIAL_STATE, { ctaLocation }) => {
    return { ...state, ctaLocation };
};

export const setRTO = (state = INITIAL_STATE, { rto }) => {
    return { ...state, rto };
};

export const updateProceedCTAStatus = (state = INITIAL_STATE, { proceedCTA }) => {
    return { ...state, proceedCTA };
};

export const makeLead = (state = INITIAL_STATE) => {
    return { ...state, error: null };
};

export const setFuelType = (state = INITIAL_STATE, { carFuelType }) => {
    return {
        ...state,
        fuelType: {
            displayText: carFuelType
        }
    };
};

export const setTransmission = (state = INITIAL_STATE, { transmission }) => {
    return {
        ...state,
        transmission: {
            displayText: transmission
        }
    };
};

export const setVariantActiveScreen = (state = INITIAL_STATE, { variantActiveScreen }) => {
    return {
        ...state,
        variantActiveScreen
    };
};

export const setLeadMobile = (state = INITIAL_STATE, { mobile }) => {
    return {
        ...state,
        mobile: { displayText: mobile, id: null }
    };
};

export const askUserIntent = (state = INITIAL_STATE, { flag }) => {
    return { ...state, askUserIntent: flag };
};

export const setUserIntent = (state = INITIAL_STATE, { intent }) => {
    return { ...state, userIntent: intent };
};

export const setOwnerName = (state = INITIAL_STATE, { ownerName }) => {
    return {
        ...state,
        ownerName
    };
};

export const setUserCarRgistrationNo = (state = INITIAL_STATE, { carRegistrationNo }) => {
    return {
        ...state,
        carRegistrationNo
    };
};

export const setAllStepFilled = (state = INITIAL_STATE, { isAllStepFill }) => {
    return {
        ...state,
        isAllStepFill
    };
};

export const setShowWidget = (state = INITIAL_STATE, { flag }) => {
    return {
        ...state,
        showWidget: flag
    };
};

export const HANDLERS = {
    [Types.SET_BRAND]: setBrand,
    [Types.SET_MODEL]: setModel,
    [Types.SET_YEAR]: setYear,
    [Types.SET_VARIANT]: setVariant,
    [Types.SET_BRANCH]: setBranch,
    [Types.SET_REGISTRATION_STATE]: setRegistrationState,
    [Types.SET_KILOMETER_DRIVEN]: setKilometerDriven,
    [Types.SET_CURRENT_STEP]: setStep,
    [Types.MAKE_LEAD]: makeLead,
    [Types.MAKE_LEAD_SUCCESS]: makeLeadSuccess,
    [Types.MAKE_LEAD_FAILURE]: makeLeadFailure,
    [Types.SHOW_PROCEED_BUTTON]: showProceedButton,
    [Types.ACTIVATE_LAST_STEP]: activateLastStep,
    [Types.ACTIVATE_FIRST_STEP]: activateFirstStep,
    [Types.MERGE_LEAD_DATA]: mergeLeadData,
    [Types.RESET_LEAD_FORM_DATA]: resetLeadFormData,
    [Types.SET_FLOW_NAME]: setFlowName,
    [Types.UPDATE_CTA_LOCATION]: updateCTALocation,
    [Types.SET_RTO]: setRTO,
    [Types.UPDATE_PROCEED_CTA_STATUS]: updateProceedCTAStatus,
    [Types.SET_FUEL_TYPE]: setFuelType,
    [Types.SET_TRANSMISSION_TYPE]: setTransmission,
    [Types.SET_VARIANT_ACTIVE_SCREEN]: setVariantActiveScreen,
    [Types.SET_MOBILE_ACTIVE_SCREEN]: setLeadMobile,
    [Types.SET_ALL_STEP_COMPLETE]: setAllStepComplete,
    [Types.SET_LEAD_SCORE]: setLeadScore,
    [Types.ASK_USER_INTENT]: askUserIntent,
    [Types.SET_USER_INTENT]: setUserIntent,
    [Types.SET_OWNER_NAME]: setOwnerName,
    [Types.ALL_STEP_FILLED]: setAllStepFilled,
    [Types.SET_USER_CAR_REGISTRATION_NUMBER]: setUserCarRgistrationNo,
    [Types.SHOW_WIDGET]: setShowWidget
};

export default createReducer(INITIAL_STATE, HANDLERS);
