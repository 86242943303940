import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import H2 from "../../shared/h2/component";
import Slider from "../../shared/slick-wrapper";
import SimilarCarCardSeo from "../similar-car-card-seo";
import { getFullName } from "../../../utils/helpers/seo-car-config";

const SimilarCarsSeo = ({
    carDetail
}) => {
    const { pageData: {
        mediaConfigUrl,
        relatedModelList,
        make, model
    }, currentSEOSelectedCity } = carDetail;
    const { label: cityName } = currentSEOSelectedCity;
    const carFullName = getFullName(make, model);
    const settings = {
        slidesToShow: 4.1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false
    };
    return (
        <React.Fragment>
            {relatedModelList && relatedModelList.length > 0 && <div styleName={"styles.sectionWrapper"}>
                <div className="container">
                    <H2 text={`SIMILAR CARS TO ${carFullName}`} />
                    <Slider {...settings}>
                        {relatedModelList.map((data, index) => (
                            <SimilarCarCardSeo relatedData={data} currentCity={cityName} mediaConfigUrl={mediaConfigUrl} key={index} />
                        ))}

                    </Slider>
                </div>
            </div>}
        </React.Fragment>

    );
};

SimilarCarsSeo.propTypes = {
    carDetail: PropTypes.object
};

export default SimilarCarsSeo;

