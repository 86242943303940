import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    data: [],
    error: null,
    isLoading: true
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, data, error: null, isLoading: false };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [], isLoading: false };
};

export const setIsLoading = (state = INITIAL_STATE, { isLoading }) => {
    return { ...state,  isLoading };
};

export const HANDLERS = {
    [Types.FETCH_LEAD_STATES_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_LEAD_STATES_FAILURE]: fetchDataFailure,
    [Types.SET_IS_LOADING]: setIsLoading
};

export default createReducer(INITIAL_STATE, HANDLERS);
