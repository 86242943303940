import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_USER_CAR_REGISTRATION_NO
    IS_CHANGING_PRE_FETCHED_DETAIL
    FETCH_REGISTRATION_NO_DETAIL
    STORE_REGISTRATION_NO_DETAIL
    RESET_REGISTRATION_DATA
    ALL_DETAIL_FETCHED_CORRECTLY
    SET_ERROR
    SET_DUPLICATE_INSPECTION_INFO
    SET_IS_REPEAT_INSPECTION
`,
    {
        prefix: "registrationno/"
    }
);

