import React, { useEffect } from "react";
import PropTypes from "prop-types";
import KeySpecificationsFeaturesSeo from "../key-specifications-features-seo";
import TopCitiesPriceSeo from "../top-cities-price-seo";
import CarVariantsSeo from "../car-variants-seo";
import CarMileageSeo from "../car-mileage-seo";
import CarServiceMaintenanceCostSeo from "../car-service-maintenance-cost-seo";
import { getBuyUsedCarUrl, getFullName, getSeoCarName } from "../../../utils/helpers/seo-car-config";
import { FALLBACK_C2B_CITY_ID } from "../../../constants/app-constants";
import CarColorOptionsSeo from "../car-color-options-seo";
import CarImagesSeo from "../car-images-seo";
import SimilarCarsSeo from "../similar-cars-seo";
import UsedCarSeo from "../used-car-seo";
import FaqSeoLanding from "../faq-seo-landing";
import SeoCarOverviewTabs from "../seo-car-overview-tabs";
import Skeleton from "./skeleton";
import SellMyCarSeo from "../sell-my-car-seo";
import { Helmet } from "react-helmet-async";
import { appUrl } from "../../../constants/url-constants";
import CarProsConsSeo from "../car-pros-cons-seo";
import CarReviewSeo from "../car-review-seo";
import CarLatestUpdatesSeo from "../car-latest-updates-seo";
import loadable from "@loadable/component";
import PageLoader from "../../shared/page-loader";
const SeoCarDetailsAtf = loadable(() => import("../seo-car-details-atf"), {fallback: <PageLoader/>});

const SeoCarOverview = ({
    carDetail,
    location,
    fetchSEODetailsConnect,
    fetchCarListConnect,
    history

}) => {
    const { isSSR, currentSEOSelectedCity, tabs, isLoading, pageData } = carDetail;
    const { value: cityId = "" } = currentSEOSelectedCity || {};
    const { make = "", model = "" } = pageData || {};
    const seoCarName = getSeoCarName(location.pathname);
    const carFullName = getFullName(make, model);
    const pageSlug = tabs.length > 0 ? tabs.find(e => e.page_type === "overview").slug : "";

    useEffect(() => {
        if (!isSSR) {
            fetchSEODetailsConnect({ slug: seoCarName, city: cityId || FALLBACK_C2B_CITY_ID });
            window.scrollTo(0, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSSR, seoCarName, cityId]);

    useEffect(() => {
        const payload = cityId || FALLBACK_C2B_CITY_ID;
        const uri = getBuyUsedCarUrl(payload, seoCarName);
        fetchCarListConnect(uri);
    }, [cityId, fetchCarListConnect, seoCarName]);

    const onPageRedirection = (pageType, isHistory = false, event = null) => {
        if (event) event.preventDefault();
        const url = tabs.find(e => pageType === e.page_type);
        const redirectionUrl = url ? `${url.slug}/` : "";
        if (isHistory) {
            history.push(`/${redirectionUrl}`);
        } else {
            return `${appUrl()}/${redirectionUrl}`;
        }
        return null;
    };

    return (
        isLoading ? (
            <Skeleton />
        ) : (
            <React.Fragment>
                <Helmet>
                    <title>{carFullName} Price in India, Specs, Colors, Images, Variants, Mileage, Service Costs</title>
                    <meta name="description" content={`${carFullName} Price in India - Get on road price of ${carFullName} in your city. Also view, ${model} Colours, Specs, Images, Mileage, Colours and Variants at Cars24.`} />
                    <meta name="ROBOTS" content="INDEX,FOLLOW" />
                    <link href={`${appUrl()}/${pageSlug}/`} rel="canonical" />
                </Helmet>
                <SeoCarOverviewTabs pageTabs={tabs} />
                <SeoCarDetailsAtf onPageRedirection={onPageRedirection} />
                <SellMyCarSeo />
                <CarMileageSeo onPageRedirection={onPageRedirection} />
                <CarLatestUpdatesSeo />
                <TopCitiesPriceSeo seoCarName={seoCarName} />
                <CarColorOptionsSeo onPageRedirection={onPageRedirection} />
                <CarProsConsSeo />
                <CarImagesSeo onPageRedirection={onPageRedirection} />
                <CarReviewSeo />
                <KeySpecificationsFeaturesSeo onPageRedirection={onPageRedirection} />
                <CarVariantsSeo onPageRedirection={onPageRedirection} />
                <CarServiceMaintenanceCostSeo onPageRedirection={onPageRedirection} />
                <SimilarCarsSeo />
                <FaqSeoLanding onPageRedirection={onPageRedirection} />
                <UsedCarSeo onPageRedirection={onPageRedirection} />
            </React.Fragment>
        )

    );
};

SeoCarOverview.propTypes = {
    carDetail: PropTypes.object,
    fetchSEODetailsConnect: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object,
    updateCurrentSEOCityConnect: PropTypes.object,
    fetchCarListConnect: PropTypes.func
};

export default SeoCarOverview;
