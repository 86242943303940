export const BUY_USED_CAR_HOME_TEMPLATES = {
    USED_CAR_INDIA_HOMEPAGE_MSITE: "usedCarIndiaHomepageMsite",
    USED_CAR_INDIA_HOMEPAGE_DESKTOP: "usedCarIndiaHomepageDesktop"
};

export const USED_CAR_INDIA_HOMEPAGE_SECTIONS = {
    USED_CAR_INDIA_HOMEPAGE_HEADER: "USED_CAR_INDIA_HOMEPAGE_HEADER",
    USED_CAR_INDIA_HOMEPAGE_DESCRIPTION: "USED_CAR_INDIA_HOMEPAGE_DESCRIPTION",
    POPULAR_BRAND: "POPULAR_BRAND",
    FEATURED_CARS: "FEATURED_CARS",
    WHY_BUY_FROM_US: "WHY_BUY_FROM_US",
    BUDGET_FACETS: "BUDGET_FACETS",
    POPULAR_CITIES: "POPULAR_CITIES",
    USED_CAR_HAPPY_STORY_SECTION: "USED_CAR_HAPPY_STORY_SECTION",
    USED_CARS_INDIA_CARS_BY_CATEGORY: "USED_CARS_INDIA_CARS_BY_CATEGORY",
    USED_CAR_RATING_SECTION: "USED_CAR_RATING_SECTION",
    USED_CAR_REVIEW_SECTION: "USED_CAR_REVIEW_SECTION",
    USED_CAR_NEED_HELP_SECTION: "USED_CAR_NEED_HELP_SECTION",
    USED_CAR_CROSS_LOB_SECTION: "USED_CAR_CROSS_LOB_SECTION",
    USED_CAR_FAQ_SECTION: "USED_CAR_FAQ_SECTION",
    USED_CAR_ATF_SECTION: "USED_CAR_ATF_SECTION",
    USED_CAR_FOOTER_SEO_LINKS_SECTION: "USED_CAR_FOOTER_SEO_LINKS_SECTION",
    USED_CAR_DOWNLOAD_APP_SECTION: "USED_CAR_DOWNLOAD_APP_SECTION",
    USED_CAR_INDIA_HOME_PAGE_BREADCRUMB: "USED_CAR_INDIA_HOME_PAGE_BREADCRUMB"
};

export const downloadAppSectionKeys = {
    DOWNLOAD_QR_CODE: "USED_CARS_INDIA_DOWNLOAD_QR_CODE",
    DOWNLOAD_BACKGROUND: "USED_CARS_INDIA_DOWNLOAD_BACKGROUND",
    DOWNLOAD_APP_STORE: "USED_CAR_INDIA_DOWNLOAD_APP_STORE",
    DOWNLOAD_PLAY_STORE: "USED_CARS_INDIA_DOWNLOAD_PLAY_STORE"
};

export const CMS_SEO_TEMPLATE_TYPE = {
    BUY_USED_CAR: "BUY_USED_CAR"
};

export const USED_CAR_SLUG = "buy-used-cars";

export const labelConstants = {
    "ASK_ON_WHATSAPP": "whatsapp",
    "REQUEST_CALLBACK": "callback",
    "FAQ": "faq",
    "MAIL_US": "mail"
};
