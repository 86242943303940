import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TopCitiesPriceSeo from "./component";
import { withRouter } from "react-router";
import { updateCurrentSEOCity, updateSSRStatus } from "../seo-car-overview/actions";

const mapStateToProps = ({
    seoCarOverview: carDetail
}) => ({
    carDetail
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateCurrentSEOCityConnect: updateCurrentSEOCity,
    updateSSRStatusConnect: updateSSRStatus
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopCitiesPriceSeo));
