/* eslint-disable camelcase */
export default (data) => {
    const {
        excellent_min_quote_price,
        fair_max_quote_price,
        good_max_quote_price,
        good_min_quote_price,
        very_good_max_quote_price,
        very_good_min_quote_price,
        max_quote_price,
        min_quote_price,
        c2c_fair,
        c2c_good,
        c2c_great
    } = data;

    return {
        fair_text: { min: parseInt(min_quote_price), max: parseInt(fair_max_quote_price) },
        good_text: { min: parseInt(good_min_quote_price), max: parseInt(good_max_quote_price) },
        very_good_text: {
            min: parseInt(very_good_min_quote_price), max: parseInt(very_good_max_quote_price)
        },
        excellent_text: {
            min: parseInt(excellent_min_quote_price),
            max: parseInt(max_quote_price)
        },
        c2c_prices: {
            min: parseInt(c2c_great),
            medium: parseInt(c2c_good),
            max: parseInt(c2c_fair)
        }
    };
};
