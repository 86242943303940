import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FaqSeoLanding from "./component";

const mapStateToProps = ({
    seoCarOverview: carDetail
}) => ({
    carDetail
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FaqSeoLanding);
