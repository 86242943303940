import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    carList: [],
    hubsAndParkingYards: []
};

export const fetchCarListSuccess = (state = INITIAL_STATE, {data, baseUrl, hubs, cities, nearestCities, hubsAndParkingYards}) => {
    return { ...state, carList: data, baseUrl, hubs, cities, nearestCities, hubsAndParkingYards};
};

export const HANDLERS = {
    [Types.FETCH_CAR_LIST_SUCCESS]: fetchCarListSuccess
};

export default createReducer(INITIAL_STATE, HANDLERS);
