import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import { withRouter } from "react-router";
import loadable from "@loadable/component";
import PageLoader from "../../shared/page-loader";
const SellCarInOneVisit = loadable(() => import("../sell-car-in-one-visit"), {fallback: <PageLoader/>});

const sellCarText = {
    header: "Selling a car? We’re buying!",
    description: `Book an appointment for free car inspection and sell it instantly, same day!`
};

const SellMyCarSeo = ({history}) => {
    return (
        <div styleName={"styles.wrapper"}>
            <SellCarInOneVisit hasCustomButton {...sellCarText}>
                <Button text="SELL MY CAR" onClick={() => history.push("/car-evaluation")}/>
                <p styleName={"styles.displayText"}>It only takes 30 seconds</p>
            </SellCarInOneVisit>
        </div>
    );
};

SellMyCarSeo.propTypes = {
    history: PropTypes.object
};

export default withRouter(SellMyCarSeo);
