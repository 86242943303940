import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SimilarCarsSeo from "./component";
import { withRouter } from "react-router";

const mapStateToProps = ({
    seoCarOverview: carDetail
}) => ({
    carDetail
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SimilarCarsSeo));
