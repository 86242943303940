import { reducerConstants } from "../../../constants/reducer-constant";
import { INITIAL_STATE as seoModelReducerInitialState, HANDLERS as seoModelReducerHandler } from "../../../components/desktop/popular-model/reducers";
import { INITIAL_STATE as leadDataReducerInitialState, HANDLERS as leadDataReducerHandler } from "../../../components/desktop/lead-form/reducers";
import { INITIAL_STATE as expertsReducerInitialState, HANDLERS as expertsReducerHandler } from "../../../components/desktop/expert-say/reducers";
import { INITIAL_STATE as recentlyInspectedReducerInitialState, HANDLERS as recentlyInspectedReducerHandler } from "../../../components/desktop/recently-inspected/reducers";
import { INITIAL_STATE as ratedTestimonialReducerInitialState, HANDLERS as ratedTestimonialReducerHandler } from "../../../components/desktop/testimonial-carousel/reducers";
import { INITIAL_STATE as sellUsedCarSeoReducerInitialState, HANDLERS as sellUsedCarSeoReducerHandler } from "../../../components/desktop/sell-used-car-seo/reducers";
import { INITIAL_STATE as registrationNoReducerInitialState, HANDLERS as registrationNoReducerHandler } from "../../../components/desktop/car-registration-number/reducer";
import { INITIAL_STATE as bannerWidgetReducerInitialState, HANDLERS as bannerWidgetReducerHandler } from "../../../components/desktop/banner-widget/reducers";
import { INITIAL_STATE as testimonialsReducerInitialState, HANDLERS as testimonialsReducerHandler } from "../../../components/desktop/testimonials/reducers";
import { INITIAL_STATE as ratedTestimonialsReducerInitialState, HANDLERS as ratedTestimonialsReducerHandler } from "../../../components/desktop/testimonial-carousel/reducers";
import { INITIAL_STATE as leadYearsInitialState, HANDLERS as leadYearsHandler } from "../../../components/desktop/lead-year/reducers";
import { INITIAL_STATE as modelsReducerInitialState, HANDLERS as modelsReducerHandler } from "../../../components/desktop/lead-models/reducers";
import { INITIAL_STATE as variantsReducerInitialState, HANDLERS as variantsReducerHandler } from "../../../components/desktop/lead-variant/reducers";
import { INITIAL_STATE as registrationStateReducerInitialState, HANDLERS as registrationStateReducerHandler } from "../../../components/desktop/lead-registration-state/reducers";
import { INITIAL_STATE as leadRTOReducerInitialState, HANDLERS as leadRTOReducerHandler } from "../../../components/desktop/lead-rto-city/reducers";
import { INITIAL_STATE as kilometersDrivenReducerInitialState, HANDLERS as kilometersDrivenReducerHandler } from "../../../components/desktop/lead-kilometer-driven/reducers";
import { INITIAL_STATE as userDropWidgetReducerInitialState, HANDLERS as userDropWidgetReducerHandler } from "../../../components/desktop/user-journey-drop/reducers";
import { INITIAL_STATE as appointmentLocationReducerInitialState, HANDLERS as appointmentLocationReducerHandler } from "../../../components/desktop/appointment/reducers";

export default {
    [reducerConstants.SEO_MODELS]: { state: seoModelReducerInitialState, handler: seoModelReducerHandler },
    [reducerConstants.LEAD_DATA]: { state: leadDataReducerInitialState, handler: leadDataReducerHandler },
    [reducerConstants.EXPERTS]: { state: expertsReducerInitialState, handler: expertsReducerHandler },
    [reducerConstants.RECENTLY_INSPECTED]: { state: recentlyInspectedReducerInitialState, handler: recentlyInspectedReducerHandler },
    [reducerConstants.RATED_TESTIMONIALS]: { state: ratedTestimonialReducerInitialState, handler: ratedTestimonialReducerHandler },
    [reducerConstants.SELL_USED_CAR_SEO]: { state: sellUsedCarSeoReducerInitialState, handler: sellUsedCarSeoReducerHandler },
    [reducerConstants.REG_NO]: { state: registrationNoReducerInitialState, handler: registrationNoReducerHandler },
    [reducerConstants.SELLER_WIDGET]: { state: bannerWidgetReducerInitialState, handler: bannerWidgetReducerHandler },
    [reducerConstants.TESTIMONIALS]: { state: testimonialsReducerInitialState, handler: testimonialsReducerHandler },
    [reducerConstants.RATED_TESTIMONIALS]: { state: ratedTestimonialsReducerInitialState, handler: ratedTestimonialsReducerHandler },
    [reducerConstants.YEARS]: { state: leadYearsInitialState, handler: leadYearsHandler },
    [reducerConstants.MODELS]: { state: modelsReducerInitialState, handler: modelsReducerHandler },
    [reducerConstants.VARIANTS]: { state: variantsReducerInitialState, handler: variantsReducerHandler },
    [reducerConstants.REGISTRATION_STATES]: { state: registrationStateReducerInitialState, handler: registrationStateReducerHandler },
    [reducerConstants.RTO]: { state: leadRTOReducerInitialState, handler: leadRTOReducerHandler },
    [reducerConstants.KILOMETERS_DRIVEN]: { state: kilometersDrivenReducerInitialState, handler: kilometersDrivenReducerHandler },
    [reducerConstants.USER_DROP_WIDGET]: { state: userDropWidgetReducerInitialState, handler: userDropWidgetReducerHandler },
    [reducerConstants.APPOINTMENT_LOCATION]: { state: appointmentLocationReducerInitialState, handler: appointmentLocationReducerHandler }

};

