import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_LEAD_STATES
    FETCH_LEAD_STATES_SUCCESS
    FETCH_LEAD_STATES_FAILURE
    SET_IS_LOADING
`,
    {
        prefix: "leadregistrationstate/"
    }
);
