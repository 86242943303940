export const WISHLIST_SOURCE = {
    HOME_PAGE: "Homepage",
    DETAIL_PAGE: "Detail",
    LISTING_PAGE: "Listing",
    RECENTLY_ADDED: "RECENTLY_ADDED",
    SIMILAR_CARS: "SIMILAR_CARS",
    MY_CARS: "MyCars",
    CF_LANDING_PAGE: "CfLandingPage"
};
export const WISHLIST_PAGE_SOURCE = {
    "Homepage": "Buy_used_cars_B2C_Homepage",
    "Listing": "Buy_used_cars_B2C_Car_Listing_Page",
    "Detail": "Buy_used_cars_B2C_Car_Details_Page",
    "MyCars": "MyCars"
};
