/* eslint-disable camelcase, complexity */
import React from "react";
import PropTypes from "prop-types";
import { sendCustomGaEvents } from "../../../tracking";
import { footerClick } from "./tracking";
import { seoLinkClicked } from "../../../constants/tracking-constants/super-home-events";
import { Footer as DlsFooter } from "@cars24/turbo-web/lib/footer";

const Footer = (props) => {
    const {
        footer,
        route
    } = props;

    const superHomeRoute = route && route.name === "buy-used-cars";

    const sendFooterEvent = (_e, text) => {
        if (superHomeRoute) {
            sendCustomGaEvents({
                ...seoLinkClicked,
                eventLabel: text
            });
        } else {

            sendCustomGaEvents({
                ...footerClick,
                eventCategory: route.name
            });

        }
    };

    return (
        <div>
            <DlsFooter
                data={footer?.pageSections[0]}
                isRevamped
                lazyLoadImage
                eventActions={{
                    footerEvent: sendFooterEvent
                }}
                gradientColor= {"linear-gradient(270deg, rgba(251, 255, 246, 0.20) 0.45%, rgba(255, 255, 255, 0.00) 194.45%), linear-gradient(1deg, rgba(171, 254, 214, 0.20) 15.19%, rgba(228, 254, 186, 0.20) 76.1%, rgba(255, 255, 255, 0.20) 105.61%)"}
            />
        </div>
    );
};

Footer.propTypes = {
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    route: PropTypes.object
};

export default Footer;
