import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import PilotFaq from "../pilot-faq/component";
import { getFullName } from "../../../utils/helpers/seo-car-config";
import ArrowIcon from "../../shared/arrow";

const FaqSeoLanding = ({
    carDetail,
    onPageRedirection
}) => {
    const { pageData: { overview: { faqs }, make, model } } = carDetail;
    const carFullName = getFullName(make, model);
    const getFaq = (faqSEO) => {
        const seoFaq = [];
        Object.keys(faqSEO).map((key) => (
            faqSEO[key].length > 0 && faqSEO[key].map((keyInside, indexInner) => (
                seoFaq.push({
                    id: indexInner + 1,
                    question: keyInside.seo_faq_question,
                    answer: keyInside.seo_faq_answer
                })
            ))
        ));
        return seoFaq;
    };
    return (
        <React.Fragment>
            {getFaq(faqs).length > 0 && <div className={"container mb-5"} styleName="styles.faq">
                <PilotFaq
                    faqs={faqs}
                    columns="1"
                    chunkSize={1}
                    heading={`FAQ’s ABOUT ${carFullName}`}
                    attachSchema={false}
                    faqs={getFaq(faqs)}
                    pushEvent={true} />
                <div styleName={"styles.readMore"} onClick={onPageRedirection.bind(null, "faqs", true)}>VIEW MORE FAQs<ArrowIcon /></div>
            </div>}
        </React.Fragment>

    );
};

FaqSeoLanding.propTypes = {
    carDetail: PropTypes.object,
    onPageRedirection: PropTypes.func
};

export default FaqSeoLanding;
