import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_EXPERTS_DATA
    FETCH_EXPERTS_DATA_SUCCESS
    FETCH_EXPERTS_DATA_FAILURE
    `,
    {
        prefix: "expertsay/"
    }
);
