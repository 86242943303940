import { createTypes } from "../../../utils/redux/create-types";

//
export default createTypes(
    `
    FETCH_WIDGET_DATA
    FETCH_WIDGET_DATA_SUCCESS
    FETCH_WIDGET_DATA_FAILURE
    SET_IS_REPEAT_USER_APPLICABLE
    SET_REPEAT_USER_DETAILS
`,
    {
        prefix: "bannerwidget/"
    }
);
