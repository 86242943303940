import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withReducer from "../../../components/shared/with-reducer";
import withLayout from "../../../components/desktop/layout/component";
import PropTypes from "prop-types";
import { HTTP_CODES } from "../../../constants/app-constants";
import NotFound from "../../../components/shared/page-not-found";
import { selectCity } from "../../../components/desktop/cities/actions";
import { resetLeadFormData, setData, setStep } from "../../../components/desktop/lead-form/actions";
import { setFirstLoad } from "../../../components/configuration/actions";
import { updateLeadSource } from "../../../components/desktop/settings/actions";
import ErrorHandler from "../../../components/shared/with-error-handler";
import SeoCarOverview from "../../../components/desktop/seo-car-overview";
import { withRouter } from "react-router";
import loadable from "@loadable/component";
import PageLoader from "../../../components/shared/page-loader";
const SellUsedCarSeo = loadable(() => import("../../../components/desktop/sell-used-car-seo"), {fallback: <PageLoader/>});
import injectableReducers from "./injectable";

const Seo = ({
    status,
    isNewSeoPage
}) => {
    if (status === HTTP_CODES.NOT_FOUND || status === HTTP_CODES.PAGE_NOT_FOUND) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            {isNewSeoPage ? <ErrorHandler>
                <SeoCarOverview />
            </ErrorHandler> : (
                <SellUsedCarSeo topCityKey={"appointment_city"}/>
            )
            }
        </React.Fragment>
    );
};

Seo.propTypes = {
    match: PropTypes.object.isRequired,
    status: PropTypes.number,
    isNewSeoPage: PropTypes.bool
};

const mapStateToProps = ({
    meta: {  status, isNewSeoPage }
}) => ({
    status,
    isNewSeoPage
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    selectCityConnect: selectCity,
    resetLeadFormDataConnect: resetLeadFormData,
    setFirstLoadConnect: setFirstLoad,
    updateLeadSourceConnect: updateLeadSource,
    setDataConnect: setData,
    setStepConnect: setStep
}, dispatch);

export default withReducer(injectableReducers)(() => ({}), dispatch => ({ dispatch }))(
    withRouter(withLayout(connect(mapStateToProps, mapDispatchToProps)(Seo))));

