import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    locationName: "",
    placeID: "",
    selectionType: null,
    leadData: null,
    error: null,
    leadDetailData: null,
    isFetching: null
};

export const setCurrentLocationName = (state = INITIAL_STATE, { locationName }) => {
    return { ...state, locationName };
};

export const setCurrentLocationID = (state = INITIAL_STATE, { placeID }) => {
    return { ...state, placeID };
};

export const setSelectionType = (state = INITIAL_STATE, { selectionType }) => {
    return { ...state, selectionType };
};
export const setLeadDetailData = (state = INITIAL_STATE, { data }) => {
    return { ...state, leadDetailData: data };
};
export const setFetchingLeadDetailData = (state = INITIAL_STATE, { data }) => {
    return { ...state, isFetching: data };
};

export const HANDLERS = {
    [Types.SET_CURRENT_LOCATION_NAME]: setCurrentLocationName,
    [Types.SET_CURRENT_LOCATION_ID]: setCurrentLocationID,
    [Types.SET_SELECTION_TYPE]: setSelectionType,
    [Types.FETCH_LEAD_DETAILS]: setLeadDetailData,
    [Types.IS_FETCH_LEAD_DETAILS]: setFetchingLeadDetailData

};

export default createReducer(INITIAL_STATE, HANDLERS);
