import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

//
export const INITIAL_STATE = {
    data: [],
    error: null
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, data };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [] };
};

export const setIsRepeatUserApplicable = (state = INITIAL_STATE, { isRepeatUserApplicable }) => {
    return { ...state, isRepeatUserApplicable };
};

export const setRepeatUserDetails = (state = INITIAL_STATE, { repeatUserDetails }) => {
    return { ...state, repeatUserDetails };
};

export const HANDLERS = {
    [Types.FETCH_WIDGET_DATA_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_WIDGET_DATA_FAILURE]: fetchDataFailure,
    [Types.SET_IS_REPEAT_USER_APPLICABLE]: setIsRepeatUserApplicable,
    [Types.SET_REPEAT_USER_DETAILS]: setRepeatUserDetails
};

export default createReducer(INITIAL_STATE, HANDLERS);
