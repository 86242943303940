import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_BRAND
    SET_MODEL
    SET_YEAR
    SET_REGISTRATION_STATE
    SET_VARIANT
    SET_BRANCH
    SET_KILOMETER_DRIVEN
    SET_RTO
    SET_CURRENT_STEP
    MAKE_LEAD
    MAKE_LEAD_SUCCESS
    MAKE_LEAD_FAILURE
    SHOW_PROCEED_BUTTON
    ACTIVATE_LAST_STEP
    ACTIVATE_FIRST_STEP

    MERGE_LEAD_DATA

    RESET_LEAD_FORM_DATA

    SET_FLOW_NAME

    UPDATE_CTA_LOCATION
    UPDATE_PROCEED_CTA_STATUS
    SET_FUEL_TYPE
    SET_TRANSMISSION_TYPE
    SET_VARIANT_ACTIVE_SCREEN
    SET_MOBILE_ACTIVE_SCREEN
    SET_ALL_STEP_COMPLETE
    SET_LEAD_SCORE
    ASK_USER_INTENT
    SET_USER_INTENT
    SET_OWNER_NAME
    ALL_STEP_FILLED
    SET_USER_CAR_REGISTRATION_NUMBER
    SHOW_WIDGET
`,
    {
        prefix: "leadform/"
    }
);
