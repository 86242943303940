import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { appUrl } from "../../../constants/url-constants";

const SeoCarOverviewTabs = ({ pageTabs, history }) => {

    const onClick = (e, slug) => {
        e.preventDefault();
        history.push(`/${slug}/`);
    };

    return (
        <div styleName={"styles.seoCarOverviewTabs"}>
            <div className="container">
                <ul>
                    {pageTabs.length > 0 && pageTabs.map((tab, index) => (
                        <li key={index} styleName={tab.tab_display === "Overview" ? "styles.active" : ""}>
                            <a onClick={(e) => onClick(e, tab.slug)} href={`${appUrl()}/${tab.slug}/`}>{tab.tab_display}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

SeoCarOverviewTabs.propTypes = {
    pageTabs: PropTypes.string,
    history: PropTypes.object
};

export default SeoCarOverviewTabs;
