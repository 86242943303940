import React, { useState, useEffect } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import H2 from "../../shared/h2/component";
import ArrowIcon from "../../shared/arrow";
import { getCombinedValue, getFullName } from "../../../utils/helpers/seo-car-config";
import priceFormatter from "../../../utils/helpers/price-formatter";
import { NUMBER } from "../../../constants/app-constants";

const CarVariantsSeo = ({
    pageData,
    onPageRedirection
}) => {
    const { make, model, overview } = pageData;
    const carFullName = getFullName(make, model);
    const { variants } = overview;
    const {
        Petrol: variantPertol = [],
        Diesel: variantsDiesel = [],
        CNG: variantsCNG = [],
        content: variantsContent
    } = variants;
    const [combinedVariants, setCombinedVariants] = useState([]);
    useEffect(() => {
        setCombinedVariants(getCombinedValue(variantPertol, variantsDiesel, variantsCNG));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variants]);
    return (
        <React.Fragment>
            {combinedVariants && combinedVariants.length > 0 &&
                <div styleName={"styles.sectionWrapper"}>
                    <div className="container">
                        <H2 text={`${carFullName} VARIANTS`} />
                        <p styleName={"styles.subTitleText"}>
                            {variantsContent}
                        </p>
                    </div>
                    <div styleName={"styles.tableHeaderStrip"}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-xs-12">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Variants</td>
                                                <td>Ex Showroom Price</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {combinedVariants.length > NUMBER.TWO && <div className="col-md-6 col-xs-12">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Variants</td>
                                                <td>Ex Showroom Price</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row mb-4">
                            {combinedVariants.map((variant, index) => (
                                <div className="col-md-6" key={index}>
                                    <p styleName={"styles.tableView"}>{variant.variantName}<span>₹{priceFormatter(variant.exShowroomPrice)}</span></p>
                                </div>
                            ))}
                        </div>
                        <a href={onPageRedirection("variants")} onClick={(event) => onPageRedirection("variants", true, event)} styleName={"styles.ctaText"}>VIEW ALL {model} VARIANTS <ArrowIcon /> </a>
                    </div>
                    {/* <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <table className="table">
                                    <tbody>
                                        {combinedVariants.map((variant, index) => (
                                            <tr key={index}>
                                                <td>{variant.variantName}</td>
                                                <td>₹ {priceFormatter(variant.exShowroomPrice)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>AlphAlpha1197 ccManual, Petrol, 21.40 kmpl</td>
                                            <td>₹ 12,00,000</td>
                                        </tr>
                                        <tr>
                                            <td>Alpha CVT1197 ccAutomatic, Petrol, 21.40 kmpl</td>
                                            <td>₹ 12,00,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <a href="" styleName={"styles.ctaText"} onClick={() => onPageRedirection("variants")}>VIEW SIX MORE VARIANTS <ArrowIcon /> </a>
                    </div> */}
                </div>
            }
        </React.Fragment>
    );
};

CarVariantsSeo.propTypes = {
    pageData: PropTypes.object,
    onPageRedirection: PropTypes.func
};

export default CarVariantsSeo;
