import React, { useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import H2 from "../../shared/h2/component";
import ArrowIcon from "../../shared/arrow";
import { NUMBER } from "../../../constants/app-constants";
import { getFullName } from "../../../utils/helpers/seo-car-config";

const KeySpecificationsFeaturesSeo = ({
    pageData,
    onPageRedirection
}) => {
    const { make, model, overview } = pageData;
    const { specifications } = overview;
    const {
        model_specifications: modelSpecifications,
        model_features: modelfeatures
        // content
    } = specifications;
    const [activeTab, setActiveTab] = useState("Features");
    const [activeData, setActiveData] = useState(modelSpecifications);
    const carFullName = getFullName(make, model);
    const changeTabs = (active) => {
        if (active === "Features") {
            setActiveTab(active);
            setActiveData(modelSpecifications);
        } else {
            setActiveTab(active);
            setActiveData(modelfeatures);
        }
    };
    return (
        <div styleName={"styles.carSpecifications"}>
            <div className="container">
                <H2 text={`${carFullName} key specification`} />
            </div>
            <div styleName={"styles.tableHeaderStrip"}>
                <div className="container">
                    {modelfeatures ?
                        <ul styleName={"styles.tabNavs"}>
                            <li styleName={activeTab === "Features" ? "styles.active" : ""}
                                onClick={() => changeTabs("Features")}>
                                    Features
                            </li>
                            {modelfeatures && <li styleName={activeTab === "Specifications" ? "styles.active" : ""}
                                onClick={() => changeTabs("Specifications")}>
                                    Specifications
                            </li>}
                        </ul> : <h3 styleName={"styles.singleTitle"}>Features</h3>
                    }
                </div>
            </div>
            <div className="container">
                <div className="row mb-4">
                    {
                        Object.keys(activeData || {}).slice(0, NUMBER.TEN).map((key, index) => (
                            <div className="col-md-6" key={index}>
                                <p styleName={"styles.tableView"}>{key} <span>{activeData[key]}</span></p>
                            </div>
                        ))
                    }
                </div>
                <a href={onPageRedirection("specifications")} onClick={(event) => onPageRedirection("specifications", true, event)}  styleName={"styles.ctaText"}>VIEW ALL {model} {activeTab} <ArrowIcon /> </a>
            </div>
        </div>
    );
};

KeySpecificationsFeaturesSeo.propTypes = {
    pageData: PropTypes.object,
    onPageRedirection: PropTypes.func
};

export default KeySpecificationsFeaturesSeo;
