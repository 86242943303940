import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    userCarRegistrationNo: "",
    dsVariantList: [],
    carRegistrationInfo: null,
    error: null,
    isChangingPreFetchedDetail: false,
    allDetailFetched: false,
    isError: false,
    duplicateInspectionInfo: {}
};

const getValue = (key, value) => {
    const defaultValue = "";
    if (key) return key[value];
    return defaultValue;
};

const checkIfEmptyVariant = (dsVariant) => {
    return !!(dsVariant && dsVariant[0] && dsVariant[0].variant);
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data }) => {
    const {
        brand,
        model,
        year,
        states,
        RTO,
        ...rest
    } = data;
    const dsVariant = (data.ds_details || []).map((item) => item.variant && item.variant.variant_id);
    return {
        ...state,
        isError: false,
        dsVariantList: checkIfEmptyVariant(data.ds_details) ? dsVariant : [],
        carRegistrationInfo: {
            brand: { id: getValue(brand, "make_id"), displayText: getValue(brand, "make_display")},
            model: { id: getValue(model, "model_id"), displayText: getValue(model, "model_display") },
            year: { id: parseInt(getValue(year, "year")) || "", displayText: getValue(year, "year").toString(), year_id: getValue(year, "year_id") },
            registrationState: { id: getValue(states, "state_id"), displayText: getValue(states, "state_code") },
            rto: { displayText: getValue(RTO, "rto_code"), id: getValue(RTO, "rto_detail_id") },
            ...rest
        }
    };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [] };
};

export const setIsChangingPreFetchedDetail = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isChangingPreFetchedDetail: flag };
};

export const resetRegistrationData = (state = INITIAL_STATE) => {
    return {
        ...state,
        isChangingPreFetchedDetail: false,
        dsVariantList: [],
        carRegistrationInfo: null,
        allDetailFetched: false,
        userCarRegistrationNo: ""
    };
};

export const setAllDetailFetchedCorrectly = (state = INITIAL_STATE, { flag }) => {
    return { ...state, allDetailFetched: flag };
};

export const settUserCarRegistrationNo = (state = INITIAL_STATE, { number }) => {
    return { ...state, userCarRegistrationNo: number };
};

export const setDuplicateInspectionInfo = (state = INITIAL_STATE, { duplicateInspectionInfo }) => {
    return { ...state, duplicateInspectionInfo };
};

export const setDataFailure = (state = INITIAL_STATE, { flag }) => {
    return {
        ...state,
        isError: flag,
        ...(!flag && {
            userCarRegistrationNo: ""
        }),
        carRegistrationInfo: null

    };
};

const setIsRepeatInspection = (state = INITIAL_STATE, { isRepeatInspection }) => {
    return { ...state, isRepeatInspection };
};

export const HANDLERS = {
    [Types.FETCH_REGISTRATION_NO_DETAIL]: fetchDataSuccess,
    [Types.IS_CHANGING_PRE_FETCHED_DETAIL]: setIsChangingPreFetchedDetail,
    [Types.RESET_REGISTRATION_DATA]: resetRegistrationData,
    [Types.SET_USER_CAR_REGISTRATION_NO]: settUserCarRegistrationNo,
    [Types.ALL_DETAIL_FETCHED_CORRECTLY]: setAllDetailFetchedCorrectly,
    [Types.SET_ERROR]: setDataFailure,
    [Types.SET_DUPLICATE_INSPECTION_INFO]: setDuplicateInspectionInfo,
    [Types.SET_IS_REPEAT_INSPECTION]: setIsRepeatInspection
};

export default createReducer(INITIAL_STATE, HANDLERS);
