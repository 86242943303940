import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import H2 from "../../shared/h2/component";
import ArrowIcon from "../../shared/arrow";
import { getFullName } from "../../../utils/helpers/seo-car-config";

const CarColorOptionsSeo = ({
    pageData,
    onPageRedirection
}) => {
    const { make, model, overview, mediaConfigUrl } = pageData;
    const carFullName = getFullName(make, model);
    const { colors: {
        colors,
        content: colorContent
    } } = overview;

    return (
        <React.Fragment>
            {colors && colors.length > 0 &&
                <div styleName={"styles.sectionWrapper"}>
                    <div className="container">
                        <H2 text={`${carFullName} COLOR OPTIONS`} />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <div styleName={"styles.carImage"}>
                                    <img src={`${mediaConfigUrl}${colors[0].images[0]}`} alt={`${carFullName} - ${colors[0].colorName}`} />
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <p styleName={"styles.contentText"}>{colorContent}</p>
                                <a href={onPageRedirection("colors")} styleName={"styles.ctaText"} onClick={(event) => onPageRedirection("colors", true, event)}>VIEW ALL {carFullName} COLORS <ArrowIcon /> </a>
                                <ul styleName={"styles.colorList"}>
                                    {colors.map((color, inedex) => (
                                        <li key={inedex} styleName={"styles.active"}>
                                            <a href={onPageRedirection("colors")}>
                                                <span styleName={"styles.colorCircle"} style={{ backgroundColor: `${color.colorCode}` }} />
                                                <label>{color.colorName}</label>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>}
        </React.Fragment>
    );
};

CarColorOptionsSeo.propTypes = {
    pageData: PropTypes.string,
    onPageRedirection: PropTypes.func
};

export default CarColorOptionsSeo;

