import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { updateCurrentPage } from "../car-list/actions";
import UsedCarSeo from "./component";

const mapStateToProps = ({
    seoCarOverview: {
        pageData = {},
        usedCars,
        currentSEOSelectedCity
    }
}) => ({ pageData, usedCars, currentSEOSelectedCity });

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateCurrentPageConnect: updateCurrentPage
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsedCarSeo));
