import React, { useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { NUMBER } from "../../../constants/app-constants";
import H2 from "../../shared/h2/component";
import ArrowIcon from "../../shared/arrow";
import { getFullName } from "../../../utils/helpers/seo-car-config";
import arrayChunks from "../../../utils/helpers/array-chunks";

const CarServiceMaintenanceCostSeo = ({
    pageData,
    onPageRedirection
}) => {
    const { make, model, overview } = pageData;
    const { service_cost: serviceCost } = overview;
    const { Petrol = [], Diesel = [], content } = serviceCost;
    const carFullName = getFullName(make, model);

    const [activeTab, setActiveTab] = useState("petrol");
    const [activeData, setActiveData] = useState(arrayChunks(Petrol, NUMBER.THREE));

    const changeTabs = (active) => {
        if (active === "petrol") {
            setActiveTab(active);
            setActiveData(arrayChunks(Petrol, NUMBER.THREE));
        } else {
            setActiveTab(active);
            setActiveData(arrayChunks(Diesel, NUMBER.THREE));
        }
    };

    const getColumnWidth = () => {
        const columnMap = {
            1: "col-12",
            2: "col-6"
        };
        return columnMap[NUMBER.TWO];
    };
    return (
        <React.Fragment>
            {Petrol && Petrol.length > 0 &&
                <div styleName={"styles.sectionWrapper"}>
                    <div className="container">
                        <H2 text={`${carFullName} SERVICE & MAINTENANCE Cost`} />
                        <p styleName={"styles.subTitleText"}>{content}</p>
                    </div>
                    <div styleName={"styles.tableHeaderStrip"}>
                        <div className="container">
                            {Diesel && Diesel.length > 0 ? <ul styleName={"styles.tabNavs"}>
                                <li styleName={activeTab === "petrol" ? "styles.active" : ""}
                                    onClick={() => changeTabs("petrol")}>Petrol</li>
                                {Diesel && Diesel.length > 0 && <li styleName={activeTab === "diesel" ? "styles.active" : ""}
                                    onClick={() => changeTabs("diesel")}>Diesel</li>}
                            </ul> : <h3 styleName={"styles.singleTitle"}>Petrol</h3>
                            }
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <table className="table mb-0">
                                    <tbody>
                                        <tr>
                                            <th>Service No.</th>
                                            <th>Kms/Months</th>
                                            <th>Type/Cost</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6">
                                <table className="table mb-0">
                                    <tbody>
                                        <tr>
                                            <th>Service No.</th>
                                            <th>Kms/Months</th>
                                            <th>Type/Cost</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row mb-4">
                            {
                                activeData.length > 0 && activeData.map((service, index) => (
                                    <div key={index} className={getColumnWidth()}>
                                        {
                                            service.length > 0 && service.map((serviceCosts, indexs) => (
                                                <div key={indexs}>
                                                    <p styleName={"styles.tableView"}>
                                                        <span>{serviceCosts.service_number}</span>
                                                        <span>{serviceCosts.kms}/{serviceCosts.months}</span>
                                                        <span>Free</span>
                                                    </p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }

                        </div>
                        <a href={onPageRedirection("service_cost")} onClick={(event) => onPageRedirection("service_cost", true, event)} styleName={"styles.ctaText"}>VIEW MORE CHARGES <ArrowIcon /> </a>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

CarServiceMaintenanceCostSeo.propTypes = {
    pageData: PropTypes.object,
    onPageRedirection: PropTypes.func
};

export default CarServiceMaintenanceCostSeo;
