import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import H2 from "../../shared/h2/component";
import { getFullName } from "../../../utils/helpers/seo-car-config";
import OverviewDescription from "../../shared/overview-description";

const CarReviewSeo = ({
    pageData
}) => {
    const { make, model, overview } = pageData;
    const {
        overview: { model_review: modelReview }
    } = overview;
    const carFullName = getFullName(make, model);
    return (
        <React.Fragment>
            {modelReview && <div styleName={"styles.sectionWrapper"}>
                <div className="container">
                    <H2 text={`${carFullName} EXPERT REVIEWS`} />
                    {/* <p styleName={"styles.subTitle"}>
                        {modelReview}
                    </p> */}
                    <OverviewDescription data={modelReview} />
                </div>
            </div>}
        </React.Fragment>
    );
};

CarReviewSeo.propTypes = {
    pageData: PropTypes.object
};

export default CarReviewSeo;
