import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_LEAD_VARIANTS
    FETCH_LEAD_VARIANTS_SUCCESS
    FETCH_LEAD_VARIANTS_FAILURE
    SET_IS_LOADING
`,
    {
        prefix: "leadvariants/"
    }
);
