import React, { useState } from "react";
import H2 from "../../shared/h2/component";
import styles from "./styles.css";
import ArrowIcon from "../../shared/arrow";
import PropTypes from "prop-types";
import arrayChunks from "../../../utils/helpers/array-chunks";
import { NUMBER } from "../../../constants/app-constants";
import { sendCustomGaEvents } from "../../../tracking";
import { getGaEventDimensionObject } from "../../../utils/helpers/get-ga-event-object";
import {InView} from "react-intersection-observer";
import SchemaValuationFaq from "../../shared/schema-valuation-faq";
import { faqView, onClickFAQ } from "./tracking-constants";

// eslint-disable-next-line no-magic-numbers
const PilotFaq = ({ faqs,
    chunkSize = NUMBER.FOUR,
    columns = NUMBER.TWO,
    heading = "Frequently Asked Questions",
    isLoggedIn,
    cityId,
    cityName,
    userId,
    attachSchema = false,
    hasListElements = false,
    accordionClickHandler = () => {}
}) => {
    const [isOpen, setIsOpen] = useState({
        1: true
    });
    const [inViews, setInView] = useState(false);
    const faqContent = arrayChunks(faqs, chunkSize);

    const getColumnWidth = () => {
        const columnMap = {
            1: "col-12",
            2: "col-6"
        };
        return columnMap[columns];
    };
    const dimensionObject = getGaEventDimensionObject({
        isLoggedIn,
        cityId,
        cityName,
        userId
    });

    if (inViews) {
        sendCustomGaEvents({
            ...faqView,
            ...dimensionObject,
            nonInteraction: true
        });
    }
    const handleOnClick = (id) => {
        accordionClickHandler(id);
        sendCustomGaEvents({
            ...onClickFAQ,
            eventLabel: id,
            ...dimensionObject
        });
    };

    return (
        <InView as="div" styleName={"styles.faq"}
            onChange={
                (inView) => {
                    setInView(inView);
                }}
        >
            <H2 text={heading} />
            <div className="row">
                {
                    faqContent.map((faqList, index) => (
                        <div key={index} className={getColumnWidth()}>
                            {
                                faqList.map(({id, question, answer }) => {
                                    const rightArrow = 0;
                                    const downArrow = 180;
                                    return (
                                        <div styleName={"styles.wrap"} key={id}>
                                            <h3 styleName={`styles.title ${isOpen[id] ?
                                                "styles.active" : ""}`}
                                            onClick={() => {
                                                handleOnClick(id);
                                                setIsOpen((state) => (
                                                    { [id]: !state[id] }
                                                ));
                                            }}
                                            >{question}
                                                <ArrowIcon
                                                    black={"BlackArrow"}
                                                    rotateBy={
                                                        isOpen[id] ? downArrow : rightArrow
                                                    } />
                                            </h3>
                                            {
                                                !hasListElements ?
                                                    <p styleName={`${ isOpen[id] ? "styles.content" : "styles.contentHide"}`}
                                                        dangerouslySetInnerHTML={
                                                            { __html: answer }
                                                        } /> :
                                                    <div styleName={`${ isOpen[id] ? "styles.content" : "styles.contentHide"}`}
                                                        dangerouslySetInnerHTML={
                                                            { __html: answer }
                                                        } />
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ))
                }

                {
                    attachSchema &&
                    <SchemaValuationFaq faqs={faqs} />
                }
            </div>
        </InView>
    );
};

PilotFaq.propTypes = {
    faqs: PropTypes.array,
    chunkSize: PropTypes.number,
    columns: PropTypes.number,
    heading: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    cityId: PropTypes.number,
    cityName: PropTypes.string,
    userId: PropTypes.number,
    attachSchema: PropTypes.bool,
    hasListElements: PropTypes.bool,
    accordionClickHandler: PropTypes.func
};

export default PilotFaq;
