import React from "react";
import { Node } from "./step-node";
import loadable from "@loadable/component";

const LeadBrands = loadable(() =>  import("../../components/desktop/lead-brands"));
const LeadModels = loadable(() =>  import("../../components/desktop/lead-models"));
const LeadYears = loadable(() =>  import("../../components/desktop/lead-year"));
const LeadVariants = loadable(() =>  import("../../components/desktop/lead-variant"));
const LeadOdometer = loadable(() =>  import("../../components/desktop/lead-odometer"));
const LeadRegistrationStates = loadable(() =>  import("../../components/desktop/lead-registration-state"));
const LeadBranches = loadable(() =>  import("../../components/desktop/lead-branches"));
const LeadBranchDate = loadable(() =>  import("../../components/desktop/lead-branch-date"));
import { createStepper } from "./stepper";
import { LEAD_FORM  } from "../../constants/app-constants";
import { NON_SELL_ONLINE_TAB_LIST, SELL_ONLINE_STEP_KEY } from "../../constants/sell-online-constant";
const AppointmentWrapper = loadable(() =>  import("../../components/desktop/appointment-wrapper"));
const ConfirmationDetails = loadable(() =>  import("../../components/desktop/confirmation-details"));

// REVAMPED  COMPONENTS //

const LeadBrandsRevamp = loadable(() =>  import("../../components/desktop/lead-brands-revamp"));
const LeadYearsRevamp = loadable(() =>  import("../../components/desktop/lead-years-revamp"));
const LeadModelsRevamp = loadable(() =>  import("../../components/desktop/lead-models-revamp"));
const LeadVariantsRevamp = loadable(() =>  import("../../components/desktop/lead-variants-revamp"));
const LeadRegistrationRevamp = loadable(() =>  import("../../components/desktop/lead-registration-state-revamp"));
const LeadOdometerRevamp = loadable(() =>  import("../../components/desktop/lead-odometer-revamp"));
const LeadCityRevamp = loadable(() =>  import("../../components/desktop/lead-city"));

const STEP_NAMES = {
    Brand: "brand",
    Model: "model",
    Year: "year",
    Variant: "variant",
    State: "registrationState",
    Confirmation: "confirmation",
    Branch: "branch",
    Date: "date",
    KilometerDriven: "kilometersDriven",
    Override: "override",
    Time: "time",
    User: "user",
    FuelType: "fuelType",
    Transmission: "transmission",
    RTO: "rto",
    City: "city"
};

const TABS = [
    { name: "Brand", index: 1, key: "brand" },
    { name: "Model", index: 2, key: "model" },
    { name: "Year", index: 3, key: "year" },
    { name: "Variant", index: 4, key: "variant" },
    { name: "State", index: 5, key: "registrationState" },
    { name: "KilometerDriven", index: 6, key: "kilometersDriven" }
];

const TABS_REVAMP = [
    { name: "Brand", index: 1, displayName: "Brand", stateName: "brand"},
    { name: "Year", index: 2, displayName: "Year", stateName: "year"},
    { name: "Model", index: 3, displayName: "Model", stateName: "model"},
    { name: "Variant", index: 4, displayName: "Variant", stateName: "variant" },
    { name: "State", index: 5, displayName: "Reg. state", stateName: "registrationState"},
    { name: "KilometerDriven", index: 6, displayName: "Kms Driven", stateName: "kilometersDriven"},
    { name: "City", index: 7, displayName: "Car location", stateName: "city"}
];

const STEPS = [
    new Node({ name: STEP_NAMES.Brand, component: LeadBrands, visited: false }),
    new Node({ name: STEP_NAMES.Model, component: LeadModels, visited: false }),
    new Node({ name: STEP_NAMES.Year, component: LeadYears, visited: false }),
    new Node({ name: STEP_NAMES.Variant, component: LeadVariants, visited: false }),
    new Node({ name: STEP_NAMES.State, component: LeadRegistrationStates, visited: false }),
    new Node({ name: STEP_NAMES.KilometerDriven, component: LeadOdometer }),
    new Node({ name: STEP_NAMES.Confirmation, component: <div>Confirmation</div>, visited: false })
];

const STEPS_REVAMP = [
    new Node({ name: STEP_NAMES.Brand, component: LeadBrandsRevamp, visited: false }),
    new Node({ name: STEP_NAMES.Year, component: LeadYearsRevamp, visited: false }),
    new Node({ name: STEP_NAMES.Model, component: LeadModelsRevamp, visited: false }),
    new Node({ name: STEP_NAMES.Variant, component: LeadVariantsRevamp, visited: false }),
    new Node({ name: STEP_NAMES.State, component: LeadRegistrationRevamp, visited: false }),
    new Node({ name: STEP_NAMES.KilometerDriven, component: LeadOdometerRevamp, visited: false }),
    new Node({ name: STEP_NAMES.City, component: LeadCityRevamp, visited: false }),
    new Node({ name: STEP_NAMES.Confirmation, component: <div>Confirmation</div>, visited: false })
];

const STEP_NAMES_APPOINTMENT = {
    Brand: "brand",
    Model: "model",
    Branch: "branch",
    Date: "date"
};

const TABS_APPOINTMENT = [
    { name: "Brand", index: 1 },
    { name: "Model", index: 2 },
    { name: "Branch", index: 3 },
    { name: "Date", index: 4 }
];

const STEPS_APPOINTMENT = [
    new Node({ name: STEP_NAMES.Brand, component: LeadBrands, visited: false }),
    new Node({ name: STEP_NAMES.Model, component: LeadModels, visited: false }),
    new Node({ name: STEP_NAMES.Branch, component: LeadBranches, visited: false }),
    new Node({ name: STEP_NAMES.Date, component: LeadBranchDate, visited: false }),
    new Node({ name: STEP_NAMES.Confirmation, component: <div>Confirmation</div>, visited: false })
];

const TAB_DATA = {
    [STEP_NAMES.Brand]: { displayText: "", id: null },
    [STEP_NAMES.Model]: { displayText: "", id: null },
    [STEP_NAMES.Year]: { displayText: "", id: null, year_id: null },
    [STEP_NAMES.Variant]: { displayText: "", id: null, variantType: null },
    [STEP_NAMES.State]: { displayText: "", id: null },
    [STEP_NAMES.KilometerDriven]: { displayText: "", id: null }
};

const REVAMP_TAB_DATA = {
    [STEP_NAMES.Brand]: { displayText: "", id: null },
    [STEP_NAMES.Year]: { displayText: "", id: null, year_id: null },
    [STEP_NAMES.Model]: { displayText: "", id: null },
    [STEP_NAMES.Variant]: { displayText: "", id: null, variantType: null },
    [STEP_NAMES.State]: { displayText: "", id: null },
    [STEP_NAMES.KilometerDriven]: { displayText: "", id: null },
    [STEP_NAMES.Mobile]: { displayText: "", id: null }
};

const TAB_DATA_APPOINTMENT = {
    [STEP_NAMES.Brand]: { displayText: "", id: null },
    [STEP_NAMES.Model]: { displayText: "", id: null },
    [STEP_NAMES.Branch]: { displayText: "", id: null, selectionType: "city" },
    [STEP_NAMES.Date]: { displayText: "", id: null }
};

const SELL_ONLINE_FORM_STEPS = [
    new Node({ name: SELL_ONLINE_STEP_KEY.BASIC_DETAILS, component: AppointmentWrapper, visited: false}),
    new Node({ name: SELL_ONLINE_STEP_KEY.CAR_CONDITION, component: AppointmentWrapper, visited: false}),
    new Node({ name: SELL_ONLINE_STEP_KEY.PRICE_ESTIMATE, component: AppointmentWrapper, visited: false}),
    new Node({ name: SELL_ONLINE_STEP_KEY.BOOK_INSPECTION, component: AppointmentWrapper, visited: false}),
    new Node({ name: SELL_ONLINE_STEP_KEY.INSPECTION_BOOKED, component: ConfirmationDetails, visited: false}),
    new Node({ name: SELL_ONLINE_STEP_KEY.RESCHEDULE, component: AppointmentWrapper, visited: false}),
    new Node({ name: "CONFIRMATION", component: <div>Confirmation</div>, visited: false })
];

const FUNNEL_CONFIG = {
    default: {
        stepper: createStepper({ steps: STEPS }),
        tabs: TABS,
        stepNames: STEP_NAMES,
        flowName: LEAD_FORM.DEFAULT,
        tabData: TAB_DATA
    },
    oneStep: {
        stepper: createStepper({ steps: STEPS_APPOINTMENT }),
        tabs: TABS_APPOINTMENT,
        stepNames: STEP_NAMES,
        flowName: LEAD_FORM.ONE_STEP,
        tabData: TAB_DATA_APPOINTMENT
    },
    defaultRevamp: {
        stepper: createStepper({ steps: STEPS_REVAMP }),
        tabs: TABS_REVAMP,
        stepNames: STEP_NAMES,
        flowName: LEAD_FORM.DEFAULT_REVAMP,
        tabData: REVAMP_TAB_DATA
    },
    appointment: {
        stepper: createStepper({ steps: SELL_ONLINE_FORM_STEPS }),
        tabs: NON_SELL_ONLINE_TAB_LIST
    }
};

const CONFIRMATION_TAB = {
    name: "Confirmation",
    index: 7
};

export {
    STEP_NAMES,
    STEPS,
    TABS,
    STEPS_APPOINTMENT,
    TABS_APPOINTMENT,
    STEP_NAMES_APPOINTMENT,
    FUNNEL_CONFIG,
    TAB_DATA,
    CONFIRMATION_TAB
};
