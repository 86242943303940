import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import H2 from "../../shared/h2/component";
import { getFullName } from "../../../utils/helpers/seo-car-config";
import priceFormatter from "../../../utils/helpers/price-formatter";
import { appUrl } from "../../../constants/url-constants";

const TopCitiesPriceSeo = ({
    carDetail,
    // updateCurrentSEOCityConnect,
    // updateSSRStatusConnect,
    seoCarName,
    history
}) => {
    const { pageData: {
        make, model, topCity } } = carDetail;
    const carFullName = getFullName(make, model);

    // const onSelection = (label, value) => {
    //     updateCurrentSEOCityConnect(label, value);
    //     updateSSRStatusConnect(false);
    // };

    const getCityUrl = (topCities, isHistory, event) => {
        if (event) event.preventDefault();
        const redirection = `${seoCarName.replace("/", "-")}-car-prices-in-${topCities.slug}`;
        if (isHistory) {
            history.push(`/${redirection}`);
        } else {
            return `${appUrl()}/${redirection}`;
        }
        return null;
    };
    return (
        <React.Fragment>
            {topCity && topCity.length > 0 && <div styleName={"styles.sectionWrapper"}>
                <div className="container">
                    <H2 text={`${carFullName} Price in India`} />
                </div>
                <div styleName={"styles.tableHeaderStrip"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>City</td>
                                            <td>Ex Showroom Price</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>City</td>
                                            <td>Ex Showroom Price</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {topCity.map((topCities, index) => (
                            <div className="col-md-6" key={index}>
                                <p styleName={"styles.tableView"} onClick={(event) =>  getCityUrl(topCities, true, event)}>
                                    <a href={getCityUrl(topCities, false)}>{topCities.city_name}</a>
                                    <span>₹{priceFormatter(topCities.ex_showroom_price)}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>}
        </React.Fragment>

    );
};

TopCitiesPriceSeo.propTypes = {
    carDetail: PropTypes.object,
    updateCurrentSEOCityConnect: PropTypes.func,
    updateSSRStatusConnect: PropTypes.func,
    onPageRedirection: PropTypes.func,
    seoCarName: PropTypes.string,
    history: PropTypes.object
};

export default TopCitiesPriceSeo;
