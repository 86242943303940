import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_SEO_DETAILS
    FETCH_SEO_DETAILS_SUCCESS
    FETCH_SEO_DETAILS_FAILURE

    FETCH_CAR_LIST
    FETCH_CAR_LIST_SUCCESS
    FETCH_CAR_LIST_FAILURE

    UPDATE_SSR_STATUS
    UPDATE_CURRENT_SEO_CITY
`,
    {
        prefix: "seocaroverview/"
    }
);
