import DoublyLinkedList from "../doubly-linked-list/doubly-linked-list";

const createStepper = ({ steps = [] }) => {
    const stepList = new DoublyLinkedList();
    for (let i = 0; i < steps.length - 1; i++) {
        stepList.append(steps[i]);
    }
    return stepList;
};

const findStep = ({ list = new DoublyLinkedList(), stepName }) => {
    return list.find({ callback: (x) => x.name === stepName });
};

export {
    createStepper,
    findStep
};
