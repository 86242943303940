import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    isLoading: false,
    isFetched: false,
    list: [],
    template: null,
    make: null,
    model: null,
    location: null,
    city: null
};

export const fetchTestimonials = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchTestimonialsSuccess = (state = INITIAL_STATE, {
    list }) => {
    return {
        ...state,
        isLoading: false,
        isFetched: true,
        list

    };
};
export const fetchSeoTestimonialsSuccess = (state = INITIAL_STATE, {
    list, template, make, model, location, city }) => {
    return {
        ...state,
        isLoading: false,
        isFetched: true,
        list,
        template,
        make,
        model,
        location,
        city
    };
};

export const fetchTestimonialsFailure = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false, isFetched: true, list: [] };
};

export const setIsSSR = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR};
};

export const HANDLERS = {
    [Types.FETCH_TESTIMONIALS]: fetchTestimonials,
    [Types.FETCH_TESTIMONIALS_SUCCESS]: fetchTestimonialsSuccess,
    [Types.FETCH_TESTIMONIALS_FAILURE]: fetchTestimonialsFailure,
    [Types.FETCH_SEO_TESTIMONIALS_SUCCESS]: fetchSeoTestimonialsSuccess,
    [Types.SET_IS_SSR]: setIsSSR
};

export default createReducer(INITIAL_STATE, HANDLERS);
