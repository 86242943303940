import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_RTO_LIST
    FETCH_RTO_LIST_SUCCESS
    FETCH_RTO_LIST_FAILURE
    UPDATE_POPULAR_RTO
`,
    {
        prefix: "rtocity/"
    }
);
