export const SOURCE = {
    MY_BOOKINGS: "MY_BOOKINGS",
    PENDING_BOOKINGS: "PENDING_BOOKINGS",
    LISTING: "LISTING",
    WISHLIST: "WISHLIST",
    WISHLIST_SIMILAR_CARS: "WISHLIST_SIMILAR_CARS",
    SIMILAR_CARS: "SIMILAR_CARS",
    RECENT_CARS: "RECENT_CARS",
    FEATURED_CARS: "FEATURED_CARS",
    CAR_COMPARE: "CAR_COMPARE",
    MY_BOOKING: "MY_BOOKING",
    PENDING_BOOKING: "PENDING_BOOKING",
    MY_PURCHASE: "MY_PURCHASE",
    CARS_BY_BODY_TYPE: "CARS_BY_BODY_TYPE",
    RECENT_CARS_HOME: "RECENT_CARS_HOME",
    RECENTLY_ADDED: "RECENTLY_ADDED",
    NEARBY_CITIES_HOME: "NEARBY_CITIES_HOME",
    NEARBY_CITIES_LISTING: "NEARBY_CITIES_LISTING",
    RECENTLY_VIEWED: "RECENTLY_VIEWED",
    FOR_YOU: "FOR_YOU"
};

export const CARD_REDIRECT_SOURCES = [
    SOURCE.MY_BOOKINGS,
    SOURCE.PENDING_BOOKINGS,
    SOURCE.MY_PURCHASE,
    SOURCE.WISHLIST,
    SOURCE.RECENTLY_VIEWED,
    SOURCE.FOR_YOU

];

export const CAR_NOTIFIER_TEXT = {
    SOLD: "Currently sold out",
    RESERVED: "Currently tokened",
    SERVICING: "Currently under service"
};
