import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarLatestUpdatesSeo from "./component";

const mapStateToProps = ({
    seoCarOverview: {
        pageData = {}
    }
}) => ({
    pageData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarLatestUpdatesSeo);
