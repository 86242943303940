export const POPULAR_USED_CARS_FOR_BACK_LINKING = [
    { displayText: "Hyundai", value: "hyundai" },
    { displayText: "Renault", value: "renault" },
    { displayText: "Mahindra", value: "mahindra" },
    { displayText: "Toyota", value: "toyota" },
    { displayText: "Audi", value: "audi" },
    { displayText: "Honda", value: "honda" },
    { displayText: "Maruti", value: "maruti" },
    { displayText: "Tata", value: "tata" },
    { displayText: "Ford", value: "ford" },
    { displayText: "Skoda", value: "skoda" }
];

export const POPULAR_CITIES_FOR_BACK_LINKING = [
    { order: 1, displayText: "New Delhi", value: "new-delhi", displayTextSell: "Delhi" },
    { order: 2, displayText: "Mumbai", value: "mumbai", displayTextSell: "Mumbai" },
    { order: 3, displayText: "Bengaluru", value: "bengaluru", displayTextSell: "Bengaluru" },
    { order: 4, displayText: "Hyderabad", value: "hyderabad", displayTextSell: "Hyderabad" },
    { order: 5, displayText: "Ahmedabad", value: "ahmedabad", displayTextSell: "Ahmedabad" },
    { order: 6, displayText: "Gurgaon", value: "gurgaon", displayTextSell: "Gurgaon" },
    { order: 7, displayText: "Chennai", value: "chennai", displayTextSell: "Chennai" },
    { order: 8, displayText: "Pune", value: "pune", displayTextSell: "Pune" },
    { order: 9, displayText: "Noida", value: "noida", displayTextSell: "Noida" },
    { order: 10, displayText: "Ghaziabad", value: "ghaziabad", displayTextSell: "Ghaziabad" },
    { order: 11, displayText: "Lucknow", value: "lucknow", displayTextSell: "Lucknow" },
    { order: 12, displayText: "Jaipur", value: "jaipur", displayTextSell: "Jaipur" },
    { order: 13, displayText: "Kolkata", value: "kolkata", displayTextSell: "Kolkata" },
    { order: 14, displayText: "Kochi", value: "kochi", displayTextSell: "Kochi" },
    { order: 15, displayText: "Indore", value: "indore", displayTextSell: "Indore" }
];

export const RESOURCES_LINKING = [
    { displayText: "Fuel Price", value: "fuel-price" },
    { displayText: "Blog", value: "news" }
    // { displayText: "Future of Mobility Report", value: "blog/cars24-reveals-future-of-mobility-report-decodes-the-pre-owned-car-buyer" }
];

