import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_USER_DROP_DATA
    SET_SSR_STATUS
    RESET_USER_DROP
`,
    {
        prefix: "userdropwidget/"
    }
);
