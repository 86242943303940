import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SeoCarOverview from "./component";
import { fetchSEODetails, fetchCarList } from "./actions";
import { withRouter } from "react-router";

const mapStateToProps = ({
    seoCarOverview: carDetail
}) => ({
    carDetail
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSEODetailsConnect: fetchSEODetails,
    fetchCarListConnect: fetchCarList
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeoCarOverview));
