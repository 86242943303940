import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import H2 from "../../shared/h2/component";
import Slider from "../../shared/slick-wrapper";
import ArrowIcon from "../../shared/arrow";
import { getFullName, getSeoCarName, removeSlash } from "../../../utils/helpers/seo-car-config";
import { FALLBACK_C2B_CITY_ID, NUMBER } from "../../../constants/app-constants";
import Filters from "../../../utils/filters";
import { SOURCE } from "../inventory-car-card-v2/constants";
import loadable from "@loadable/component";
import PageLoader from "../../shared/page-loader";
import { WISHLIST_SOURCE } from "../with-wishlist/constants";
const InventoryCarCard = loadable(() => import("../inventory-car-card"), {fallback: <PageLoader/>});

const UsedCarSeo = ({ usedCars, pageData, currentSEOSelectedCity, history, location, updateCurrentPageConnect }) => {
    const [usedCarLink, setUsedCarLink] = useState("");
    const { make, model } = pageData;
    const { value: cityId } = currentSEOSelectedCity;
    const seoCarName = getSeoCarName(location.pathname);
    const carFullName = getFullName(make, model);
    const settings = {
        slidesToShow: 3.6,
        slidesToScroll: 1,
        infinite: false,
        arrow: false
    };

    useEffect(() => {
        const payload = cityId || FALLBACK_C2B_CITY_ID;
        const carName = removeSlash(seoCarName);
        const appliedFilters = new Filters({ cityID: payload });
        setUsedCarLink(appliedFilters.getListingPayload({
            options: {
                search: carName
            }
        }).relativeURL);
    }, [cityId, seoCarName]);

    const redirectToListing = (event) => {
        event.preventDefault();
        updateCurrentPageConnect(0);
        history.push(usedCarLink);
    };
    return (
        <React.Fragment>
            {usedCars && usedCars.length > 0 && <div styleName={"styles.section"}>
                <div className="container">
                    <H2 text={`USED ${carFullName}`} />
                    <Slider {...settings}>
                        {usedCars
                            .slice(0, NUMBER.FIVE)
                            .map((item, index) => (
                                <InventoryCarCard key={index} {...item} source={SOURCE.SIMILAR_CARS} wishlistSource={WISHLIST_SOURCE.SIMILAR_CARS}/>
                            )
                            )}
                    </Slider>
                    <a href={usedCarLink} styleName={"styles.ctaText"} onClick={redirectToListing}>VIEW All USED {carFullName} <ArrowIcon /></a>
                </div>
            </div>}
        </React.Fragment>

    );
};

UsedCarSeo.propTypes = {
    usedCars: PropTypes.object,
    pageData: PropTypes.object,
    currentSEOSelectedCity: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    updateCurrentPageConnect: PropTypes.func
};

export default UsedCarSeo;
