import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Skeleton from "../h1/skeleton";

const H2 = (props) => {
    const { text, color, isLoading, withUnderline = true, isNewFont = false } = props;
    let heading = (<h2 styleName={`${isNewFont ? "styles.newFont" : ""} ${withUnderline ?  "styles.heading" : "styles.noUnderline"}`} style={{ color }}>{text}</h2>);
    if (isLoading) {
        heading = <Skeleton />;
    }
    return heading;
};

H2.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    isNewFont: PropTypes.bool,
    isLoading: PropTypes.bool,
    withUnderline: PropTypes.bool
};

export default H2;
