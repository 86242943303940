import { staticFastlyAppUrl } from "./url-constants";

export const KM_RANGE = [
    {
        "value": 5000,
        "label": "Up to 10,000 km"
    },
    {
        "value": 15000,
        "label": "10,000 - 20,000 km"
    },
    {
        "value": 25000,
        "label": "20,000 - 30,000 km"
    },
    {
        "value": 35000,
        "label": "30,000 - 40,000 km"
    },
    {
        "value": 45000,
        "label": "40,000 - 50,000 km"
    },
    {
        "value": 55000,
        "label": "50,000 - 60,000 km"
    },
    {
        "value": 65000,
        "label": "60,000 - 70,000 km"
    },
    {
        "value": 75000,
        "label": "70,000 - 80,000 km"
    },
    {
        "value": 85000,
        "label": "80,000 - 90,000 km"
    },
    {
        "value": 95000,
        "label": "90,000 - 1,00,000 km"
    },
    {
        "value": 110000,
        "label": "1,00,000 - 1,25,000 km"
    },
    {
        "value": 135000,
        "label": "1,25,000 - 1,50,000 km"
    },
    {
        "value": 165000,
        "label": "1,50,000 - 1,75,000 km"
    },
    {
        "value": 185000,
        "label": "1,75,000 - 2,00,000 km"
    },
    {
        "value": 210000,
        "label": "2,00,000 - 2,25,000 km"
    },
    {
        "value": 235000,
        "label": "2,25,000 - 2,50,000 km"
    },
    {
        "value": 260000,
        "label": "Above 2,50,000 km"
    }
];

export const APPOINTMENT_WIDGET_TYPE = {
    LEAD_NOT_CREATED: "leadNotCreated",
    WEB_QUOTE_GENERATED: "priceGenerated",
    WEB_QUOTE_NOT_FOUND: "webQuoteNotFound",
    SELL_ONLINE_QUESTION_NOT_ANSWERED: "sellOnlineQuestionNotAnswered",
    SELL_ONLINE_PRICE_GENERATED: "sellOnlinePriceGenerated",
    SELL_ONLINE_GS_NOT_ELIGIBLE: "sellOnlineGsNotEligible",
    APPOINTMENT_CREATED: "appointmentCreated",
    GS_NOT_ELIGIBLE_APPOINTMENT_CREATED: "gsNotEligibleAppointmentCreated",
    SELL_ONLINE_LEAD_CREATED: "sellOnlineLeadCreated",
    UPCOMING_APPOINTMENT: "upcomingAppointment",
    CJ_ASSIGNED: "cjAssigned",
    PASSED_APPOINTMENT: "PASSED_APPOINTMENT",
    SELL_NARROW_PRICE: "SELL_NARROW_PRICE",
    DIY_JOURNEY: "DIY_JOURNEY",
    SO_WEB_QUOTE: "SO_WEB_QUOTE"
};

export const QUESTION_VERSION = {
    VERSION1: "v1",
    VERSION2: "v2",
    VERSION3: "v3"
};

export const ODOMETETER_LABEL_ABOVE_THRESHOLD = 120000;

export const CAR_FEEDBACK = {
    POSTIIVE: "Positive",
    NEGATIVE: "Negative"
};

export const OFFER_PRICE_PAGE_TYPE = {
    NARROW_PRICE_PAGE: "narrow",
    ASSURED_PRICE_PAGE: "assured",
    WEBQUOTE_PRICE_PAGE: "web_quote"
};

export const EXPERT_CONSULTATION_SCREENS = {
    SLOT: "SLOT",
    QUESTION: "QUESTION",
    CONFIRMATION: "CONFIRMATION"
};

export const EXPERT_CONSULTATION_FLOWS = {
    TALK_TO_EXPERT: "TALK_TO_EXPERT",
    BACK_BUTTON: "BACK_BUTTON"
};

export const SCRAP_CAR_TYPES = {
    NOT_ELIGIBLE: "NOT_ELIGIBLE",
    ELIGIBLE: "ELIGIBLE",
    NON_SERVICIBLE: "NON_SERVICIBLE"
};

export const HOW_SCRAP_CAR_WORKS_VIDEO_URL = `https://www.youtube.com/watch?v=qBRiUfn04qI&ab_channel=CARS24`;

export const BANNER_MAPPING = {
    MWEB: "m-web",
    WEB: "web"
};

export const HOME_BANNER = {
    [BANNER_MAPPING.MWEB]: `${staticFastlyAppUrl()}/cars24/seo/static/53_20240124_1706116922.jpg`,
    [BANNER_MAPPING.WEB]: `${staticFastlyAppUrl()}/cars24/seo/static/53_20240124_1706117026.jpg`
};

export const LEAD_DATA_RESPONSE_ORDER = {
    states: {},
    RTO: {},
    brand: {},
    year: {},
    model: {},
    ds_details: {}
};

export const REGISTRATION_STEP_NAMES = {
    BRAND: "brand",
    YEAR: "year",
    MODEL: "model",
    STATES: "states",
    DS_DETAIL: "ds_details",
    VARIANT: "variant",
    RTO: "RTO"
};

export const TRANSMISSION_TYPES = {
    AT: "Automatic",
    MT: "Manual"
};

export const HOMEPAGE_TYPES = {
    CITY_PAGE: "citypage"
};

export const SLOT_TYPES = {
    EXPRESS: "express",
    NORMAL: "normal"
};

export const APP_ADOPTION_BASE_URL = "https://cars24.app.link";

export const APP_ADOPTION_URL = {
    DOWNLOAD_APP_ICON: `${APP_ADOPTION_BASE_URL}/qyVrNveY1Ib`,
    CONTINUE: `${APP_ADOPTION_BASE_URL}/kpZbxQkY1Ib`,
    UPLOAD_NOW: `${APP_ADOPTION_BASE_URL}/UrhDm5sY1Ib`,
    DOWNLOAD_NOW: `${APP_ADOPTION_BASE_URL}/l5ceQAAY1Ib`,
    CHECK_ON_APP: `${APP_ADOPTION_BASE_URL}/TG9NTkFY1Ib`,
    DOC_ISSUE: `${APP_ADOPTION_BASE_URL}/dXAPbaKY1Ib`,
    MODIFY_BOOKING: `${APP_ADOPTION_BASE_URL}/LuQWVNYl3Ib`,
    DOWNLOAD_APP_PRICE_PAGE: `${APP_ADOPTION_BASE_URL}/BYl7OPN6pNb`
};
export const ALLIANCE_REF_TYPES = ["cars24_yard_referral"];
export const ALLIANCE_PAGE_REF_KEY = "alliance_ref";

export const FGVGE_SCREEN_TYPES = {
    CAR_MODEL_3D: "car_model_3d",
    CAR_MODEL_IMAGE: "car_model_image"
};

export const SELLER = "seller";

export const offerStatuses = {
    OFFER_EXPIRED: "EXPIRED",
    OFFER_ACTIVE: "ACTIVE"
};

export const PRICE_TYPES = {
    excellent: "excellent_text",
    very_good: "very_good_text",
    good: "good_text",
    fair: "fair_text"
};

export const OFFER = "Offer";
export const OFFER_EXPIRED = "OfferExpired";

export const GS_TAGS = {
    PUBLISHED: "PUBLISHED",
    UNPUBLISHED: "UNPUBLISHED",
    RETURNED: "RETURNED",
    SOLD: "SOLD",
    NON_GS_CAR: "NON_GS_CAR",
    RC_TRANSFERRED: "RC_TRANSFERRED",
    LC: "LC"
};

export const  SURVEY_CLIENT_ID = process.env.SURVEY_CLIENT_ID;

export const PAGE_TEMPLATES = {
    SELL_ONLINE_FLOW: "sell-online-flow",
    SELL_ONLINE_OFFERED_PRICE: "sell-online-offered-price"
};
export const BANNER_VALUE = "appointment-confirmation-banners";

export const SO_STAGES = {
    NON_SERVICEABLE_SO_PRICE_OFFERED: "NON_SERVICEABLE_SO_PRICE_OFFERED",
    BOOKED_SO: "BOOKED_SO",
    CONFIRMED_SO: "CONFIRMED_SO",
    NON_SERVICEABLE_SO: "NON_SERVICEABLE_SO",
    NON_SERVICEABLE_SO_PRICE_NOT_OFFERED: "NON_SERVICEABLE_SO_PRICE_NOT_OFFERED"

};
