import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    data: [],
    error: null,
    isLoading: true
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, data, error: null, isLoading: false};
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [], isLoading: false};
};
export const setIsYearLoad = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isLoading: flag };
};
export const HANDLERS = {
    [Types.FETCH_LEAD_YEAR_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_LEAD_YEAR_FAILURE]: fetchDataFailure,
    [Types.SET_IS_YEAR_LOADING]: setIsYearLoad
};

export default createReducer(INITIAL_STATE, HANDLERS);
