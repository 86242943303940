import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import H2 from "../../shared/h2/component";
import { getFullName } from "../../../utils/helpers/seo-car-config";
import OverviewDescription from "../../shared/overview-description";

const CarLatestUpdatesSeo = ({
    pageData
}) => {
    // const { overview: {overview: {latest_updates: latestUpdates}}} = pageData;
    const { make, model, overview } = pageData;
    const {
        overview: { latest_updates: latestUpdates }
    } = overview;
    const carFullName = getFullName(make, model);
    return (
        <React.Fragment>
            {latestUpdates && <div styleName={"styles.sectionWrapper"}>
                <div className="container">
                    <H2 text={`${carFullName} LATEST UPDATES`} />
                    {/* <p styleName={"styles.subTitle"}>{latestUpdates}</p> */}
                    <OverviewDescription data={latestUpdates} />
                    {/* <ul styleName={"styles.contentList"}>
                        <li>Latest Update: Maruti Baleno is one of the Maruti models that has received a price hike of up to Rs 34,000.</li>
                        <li>Maruti Baleno Price: The premium hatchback is priced between Rs 5.90 lakh and Rs 9.10 lakh (ex-showroom).</li>
                        <li>Maruti Baleno Variants: The Baleno is available in four variants: Sigma, Delta, Zeta and Alpha.</li>
                        <li>Maruti Baleno Powertrain: Maruti offers the Baleno with a 1.2-litre naturally aspirated petrol engine (83PS/113Nm), mated to either a 5-speed MT</li>
                    </ul> */}
                </div>
            </div>}
        </React.Fragment>
    );
};

CarLatestUpdatesSeo.propTypes = {
    pageData: PropTypes.object
};

export default CarLatestUpdatesSeo;
