import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    data: [],
    error: null,
    apiInProgress: false,
    isLoading: true,
    whatsappStatus: true
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data: { data: { detail } } }) => {
    return { ...state, data: detail, error: null, isLoading: false };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [], isLoading: false };
};

export const updateAPIStatus = (state = INITIAL_STATE, { data }) => {
    return { ...state, apiInProgress: data };
};

export const updateWhatsappStatus = (state = INITIAL_STATE, { whatsappStatus }) => {
    return { ...state, whatsappStatus };
};

export const HANDLERS = {
    [Types.FETCH_DATA_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_DATA_FAILURE]: fetchDataFailure,
    [Types.UPDATE_API_STATUS]: updateAPIStatus,
    [Types.UPDATE_WHATSAPP_STATUS]: updateWhatsappStatus
};

export default createReducer(INITIAL_STATE, HANDLERS);
