import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import KeySpecificationsFeaturesSeo from "./component";
import { withRouter } from "react-router";
import { updateCurrentSEOCity, updateSSRStatus } from "../seo-car-overview/actions";

const mapStateToProps = ({
    seoCarOverview: {
        pageData = {},
        currentSEOSelectedCity
    }
}) => ({ pageData, currentSEOSelectedCity });

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateCurrentSEOCityConnect: updateCurrentSEOCity,
    updateSSRStatusConnect: updateSSRStatus
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KeySpecificationsFeaturesSeo));
