import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    all: [],
    popular: [],
    isSSR: false,
    isLoading: true
};

export const fetchModels = (state = INITIAL_STATE) => {
    return { ...state, isSSR: false };
};

export const fetchModelsSuccess = (state = INITIAL_STATE, { data: { all, popular } }) => {
    return { ...state, all, popular, isLoading: false };
};

export const fetchModelsFailure = (state = INITIAL_STATE) => {
    return { ...state, isSSR: false, all: [], popular: [], isLoading: false };
};

export const HANDLERS = {
    [Types.FETCH_MODELS]: fetchModels,
    [Types.FETCH_MODELS_SUCCESS]: fetchModelsSuccess,
    [Types.FETCH_MODELS_FAILURE]: fetchModelsFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);
