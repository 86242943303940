/* eslint-disable max-statements */
import Init from "./init";
import { appUrl } from "../../constants/url-constants";
import qs from "querystring";
import { FILTER_ENTITIES } from "./constants";
import parseLocationSearchParams from "../helpers/parse-location-search-params";
import { DEFAULT_C2C_CITY } from "../../constants/app-constants";

class Filters extends Init {
    constructor({ city, cityID, pincode }) {
        super();
        this._city = city;
        this._cityID = cityID || DEFAULT_C2C_CITY.city_id;
        this._defaultCity = DEFAULT_C2C_CITY.city_slug;
        this._pincode = pincode || "";
    }

    normalizeCity() {
        try {
            return this._city.toLowerCase().split(" ").join("-");
        } catch (e) {
            return this._defaultCity;
        }
    }

    getCompleteUrl(entities, data) {
        const url = ["buy", "used"];
        if (Array.isArray(entities)) {
            this.getPriorityOrder().forEach((item) => {
                if (entities.includes(item)) {
                    url.push(...(this.getEntityModifier(item))(data));
                }
            });
        } else {
            url.push("cars");
        }

        return url;
    }

    getRedirectURL(entities, data) {
        const url = this.getCompleteUrl(entities, data);
        url.push(this.normalizeCity());
        return url.join("-");
    }

    getRelativeURL() {
        return `/${this.getRedirectURL(...arguments)}/`;
    }

    getAbsoluteURL() {
        return `${appUrl()}/${this.getRedirectURL(...arguments)}/`;
    }

    getRedirectURLWithoutCityName(entities, data) {
        const value = data.value.toLowerCase().split(" ").join("-");
        const url = ["buy", "used", value, "cars"];
        return url.join("-");
    }

    getRelativeURLWithoutCityName() {
        return `/${this.getRedirectURLWithoutCityName(...arguments)}/`;
    }

    getListingPayload({ filters, options, filterType = "applied", isIndiaPage = false, latestFilterKey = "", isUrlRedirected = true }) {
        // console.log("options are=============", options);
        let payload = [];
        let urlPayload = [];
        let filterCount = 0;
        const rangedFilters = [
            FILTER_ENTITIES.YEAR,
            FILTER_ENTITIES.BUDGET,
            FILTER_ENTITIES.ODOMETER_READING
        ];
        let nonRangedfilterCount = 0;

        if (filters) {
            const filterKeys = Object.keys(filters);
            const filterValues = Object.values(filters);
            filterValues.forEach((item, index) => {
                filterCount += item[filterType].length;
                if (!rangedFilters.includes(filterKeys[index])) {
                    nonRangedfilterCount += item[filterType].length;
                }
                const normalizedPayload = item[filterType].map((appliedFilter) => {
                    let _value = appliedFilter.value;
                    if (appliedFilter.min || appliedFilter.max) {
                        _value = `${appliedFilter.min}-${appliedFilter.max}`;
                    }
                    return _value;
                });
                let key = item.apiKey;
                if (item.apiSuffix) {
                    key = `${key}${item.apiSuffix}`;
                }
                /**
                 * if parkingyard is selected as a boolean, send it as topselling
                 */
                if (item.apiKey === FILTER_ENTITIES.PARKING_YARD && normalizedPayload[0] === true) {
                    key = FILTER_ENTITIES.TOP_SELLING;
                }

                const currentKeyPayload = qs.stringify({
                    [key]: normalizedPayload
                });
                if (currentKeyPayload) {
                    payload.push(currentKeyPayload);
                }
            });
        }

        if (isIndiaPage && !isUrlRedirected && latestFilterKey.length > 0) {
            payload = payload.filter((item) => {
                return !item.includes(latestFilterKey);
            });
        }

        urlPayload = [...payload];
        if (options) {
            const ignoredUrlOptions = [
                "serveWarrantyCount",
                "gaId",
                "mobile"
            ];
            const filteredOptions = {...options};
            ignoredUrlOptions.forEach(i => delete filteredOptions[i]);
            urlPayload.push(qs.stringify(filteredOptions));
            // console.log("filteredOptions are=============", filteredOptions);
            payload.push(qs.stringify(options));
        }

        if (!isIndiaPage) {
            const cityPayload = qs.stringify({
                storeCityId: this._cityID,
                ...(
                    this._pincode && {
                        pinId: this._pincode
                    }
                )
            });
            urlPayload.push(cityPayload);
            payload.push(cityPayload);
        }
        const urlString = urlPayload.join("&");
        const payloadString = payload.join("&");

        return {
            relativeURL: `/buy-used-car?${urlString}`,
            payload: payloadString,
            absoluteURL: `${appUrl()}/buy-used-car?${urlString}`,
            filterCount,
            nonRangedfilterCount
        };
    }

    getPaginationUrl({ history, page }) {
        const queryParams = parseLocationSearchParams(history.location.search);
        if (page <= 1) {
            delete queryParams.page;
        } else {
            queryParams.page = page;
        }

        const relativeURL = [
            history.location.pathname,
            qs.stringify(queryParams)
        ].filter(i => !!i).join("?");
        const absoluteURL = `${appUrl()}${relativeURL}`;

        return { absoluteURL, relativeURL, payload: relativeURL };
    }

}

export default Filters;
