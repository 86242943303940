import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const OverviewDescription = ({
    data,
    isActive = true
}) => {

    let multiLineData = null;
    if (data) {
        multiLineData = data.split("\r\n");
    }

    return (
        multiLineData ?
            <div styleName={"styles.description"} className={isActive ? "" : "d-none"}>
                {
                    multiLineData.map((item) => (
                        item ? <p>{item}</p> : null
                    ))
                }
            </div> : null
    );
};

OverviewDescription.propTypes = {
    data: PropTypes.string,
    isActive: PropTypes.bool
};

export default OverviewDescription;
