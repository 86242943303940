import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    data: [],
    isLoading: false,
    isFetched: false
};

export const fetchRTOListInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true, isFetched: false, data: [] };
};

export const fetchRTOListSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, isLoading: false, isFetched: true, data };
};

export const fetchRTOListFailure = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false, isFetched: true, data: [] };
};

export const updatePopulatRTO = (state = INITIAL_STATE, { data }) => {
    let oldLeadRTO = [...state.data];
    const isNonPopularAlreadyExist = oldLeadRTO.find(brand => brand.is_popular === "no");
    if (isNonPopularAlreadyExist) {
        oldLeadRTO = oldLeadRTO.slice(1);
    }
    const updatedModelList = [data].concat(oldLeadRTO);
    return { ...state,  popular: updatedModelList };
};

export const HANDLERS = {
    [Types.FETCH_RTO_LIST]: fetchRTOListInit,
    [Types.FETCH_RTO_LIST_SUCCESS]: fetchRTOListSuccess,
    [Types.FETCH_RTO_LIST_FAILURE]: fetchRTOListFailure,
    [Types.UPDATE_POPULAR_RTO]: updatePopulatRTO
};

export default createReducer(INITIAL_STATE, HANDLERS);
