import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_RECENTLY_INSPECTED_DATA
        FETCH_RECENTLY_INSPECTED_DATA_SUCCESS
        FETCH_RECENTLY_INSPECTED_DATA_FAILURE
    `,
    {
        prefix: "recentlyinspected/"
    }
);
