import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_CURRENT_LOCATION_NAME
    SET_CURRENT_LOCATION_ID
    SET_SELECTION_TYPE

    FETCH_LEAD_DETAILS
    IS_FETCH_LEAD_DETAILS
`,
    {
        prefix: "appointment/"
    }
);
