import { createReducer } from "../../../utils/redux/create-reducer";
import { stringConstants } from "../../../constants/app-constants";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    all: [],
    popular: [],
    error: null,
    isLoading: true
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data: { all, popular } }) => {
    return { ...state, all, popular, error: null, isLoading: false };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, all: [], popular: [], isLoading: false };
};

export const isLoading = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isLoading: flag };
};

export const updateLeadModels = (state = INITIAL_STATE, { data }) => {
    const oldLeadModels = [...state.popular];
    const isNonPopularAlreadyExist = oldLeadModels.find(brand => brand.is_popular === "no");
    if (data.is_popular === stringConstants.YES) {
        if (isNonPopularAlreadyExist) {
            oldLeadModels.splice(0, 1);
        }
    } else {
        if (isNonPopularAlreadyExist) {
            oldLeadModels.splice(0, 1);
        }
        oldLeadModels.unshift(data);
    }

    return { ...state,  popular: oldLeadModels };
};

export const HANDLERS = {
    [Types.FETCH_DATA_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_DATA_FAILURE]: fetchDataFailure,
    [Types.UPDATE_POPULAR_MODELS]: updateLeadModels,
    [Types.SET_IS_LOADING]: isLoading
};

export default createReducer(INITIAL_STATE, HANDLERS);
