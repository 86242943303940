export const SELL_ONLINE_STEP_KEY = {
    BASIC_DETAILS: "basicdetails",
    CAR_CONDITION: "carcondition",
    FINAL_OFFER: "finaloffer",
    DOCUMENT_DETAILS: "documentdetails",
    APPOINTMENT_DETAILS: "appointmentdetails",
    DOCUMENT_UPLOAD: "documentupload",
    CONFIRMATION: "confirmation",
    PRICE_ESTIMATE: "priceEstimate",
    BOOK_INSPECTION: "bookInspection",
    INSPECTION_BOOKED: "inspectionBooked",
    RESCHEDULE: "RESCHEDULE"
};

export const NON_SELL_ONLINE_TAB_LIST = [
    {
        heading: "Basic details",
        subHeading: "",
        key: SELL_ONLINE_STEP_KEY.BASIC_DETAILS,
        index: 1
    },
    {
        heading: "Car condition",
        subHeading: "",
        key: SELL_ONLINE_STEP_KEY.CAR_CONDITION,
        index: 2
    },
    {
        heading: "Price estimate",
        subHeading: "",
        key: SELL_ONLINE_STEP_KEY.PRICE_ESTIMATE,
        index: 3
    },
    {
        heading: "Book inspection",
        subHeading: "",
        key: SELL_ONLINE_STEP_KEY.BOOK_INSPECTION,
        index: 4
    },
    {
        heading: "Inspection booked",
        subHeading: "",
        key: SELL_ONLINE_STEP_KEY.INSPECTION_BOOKED,
        index: 5
    }
];

export const SELL_ONLINE_TAB_LIST = [
    {
        heading: "Basic details",
        subHeading: "Brand, Model, Year, Variant, Fuel Type, Transmission Type, Kilometers Driven, State",
        key: SELL_ONLINE_STEP_KEY.BASIC_DETAILS,
        index: 1
    },
    {
        heading: "Car condition",
        subHeading: "",
        key: SELL_ONLINE_STEP_KEY.CAR_CONDITION,
        index: 2
    },
    {
        heading: "Final offer",
        subHeading: "",
        key: SELL_ONLINE_STEP_KEY.FINAL_OFFER,
        index: 3
    },
    {
        heading: "Schedule pick up",
        subHeading: "",
        key: SELL_ONLINE_STEP_KEY.APPOINTMENT_DETAILS,
        index: 4
    }
];
