import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    data: {
        userDropList: [],
        allCardInfo: "",
        isUserDrop: false,
        isError: false
    },
    isSSR: false
};

export const setUserDropData = (state = INITIAL_STATE, {data}) => {
    return { ...state, data};
};

export const setSsrStatus = (state = INITIAL_STATE, {isSSR}) => {
    return { ...state, isSSR};
};

export const resetUserDropData = () => {
    return INITIAL_STATE;
};

export const HANDLERS = {
    [Types.SET_USER_DROP_DATA]: setUserDropData,
    [Types.SET_SSR_STATUS]: setSsrStatus,
    [Types.RESET_USER_DROP]: resetUserDropData
};

export default createReducer(INITIAL_STATE, HANDLERS);
