import Types from "./types";
import { STEP_NAMES } from "../../../utils/funnel/steps.desktop";
import { LeadServiceJava } from "../../../service/lead-service-java-api";
import queryString from "querystring";
import { VehicleService } from "../../../service/vehicle-service";

const setStep = ({ currentStep, tabs }) => {
    return {
        type: Types.SET_CURRENT_STEP,
        currentStep,
        tabs
    };
};

const setBrand = ({ brand, stepper, tabs, tabData, registrationNo }) => ({
    type: Types.SET_BRAND,
    brand,
    stepper,
    tabs,
    tabData, registrationNo
});

const setModel = ({ model, stepper, tabs, tabData, registrationNo }) => ({
    type: Types.SET_MODEL,
    model,
    stepper,
    tabs,
    tabData, registrationNo
});

const setYear = ({ year, stepper, tabs, tabData, registrationNo  }) => ({
    type: Types.SET_YEAR,
    year,
    stepper,
    tabs,
    tabData, registrationNo
});

const setVariant = ({ variant, registrationNo }) => ({
    type: Types.SET_VARIANT,
    variant,
    registrationNo
});

const setRegistrationState = ({ registrationState, registrationNo }) => ({
    type: Types.SET_REGISTRATION_STATE,
    registrationState,
    registrationNo
});

const setBranch = ({ branch, stepper, tabs, tabData }) => ({
    type: Types.SET_BRANCH,
    branch,
    stepper,
    tabs,
    tabData
});

const setKilometerDriven = (data) => ({
    type: Types.SET_KILOMETER_DRIVEN,
    data
});

const makeLeadSuccess = (data) => ({
    type: Types.MAKE_LEAD_SUCCESS,
    data
});

const makeLeadFailure = (data) => ({
    type: Types.MAKE_LEAD_FAILURE,
    data
});

const showProceedButton = () => ({
    type: Types.SHOW_PROCEED_BUTTON
});

const activateLastStep = (data) => ({
    type: Types.ACTIVATE_LAST_STEP,
    data
});

const activateFirstStep = (data) => ({
    type: Types.ACTIVATE_FIRST_STEP,
    data
});

const makeLeadInit = () => ({
    type: Types.MAKE_LEAD
});

const makeLead = (query, payload) => dispatch => {
    dispatch(makeLeadInit());
    const stringifiedQuery = queryString.stringify(query);
    return new Promise((resolve, reject) => {
        LeadServiceJava.createLead(stringifiedQuery, payload)
            .then(response => {
                resolve(response.data.detail);
                dispatch(makeLeadSuccess(response.data.detail));
            })
            .catch(error => {
                reject(error);
                dispatch(makeLeadFailure(error));
            });
    });
};

const updateLead = (query, params) => dispatch => {
    const stringifiedQuery = queryString.stringify(query);
    return new Promise((resolve, reject) => {
        LeadServiceJava.updateLead(stringifiedQuery, params).then(response => {
            resolve(response);
            dispatch(makeLeadSuccess(response.data.detail));
        }).catch(error => {
            reject(error);
            dispatch(makeLeadFailure(error));
        });
    });
};

const mergeLeadData = (response) => ({
    type: Types.MERGE_LEAD_DATA,
    data: response
});

const setData = ({ type, data }, { stepper, tabs, tabData }) => (dispatch, getState) => {
    const registrationNo = getState().registrationNo;
    switch (type) {
    case STEP_NAMES.Brand:
        dispatch(setBrand({
            [STEP_NAMES.Brand]: data,
            stepper,
            tabs,
            tabData,
            registrationNo
        }));
        break;
    case STEP_NAMES.Model:
        dispatch(setModel({
            [STEP_NAMES.Model]: data,
            stepper,
            tabs,
            tabData,
            registrationNo
        }));
        break;
    case STEP_NAMES.Year:
        dispatch(setYear({
            [STEP_NAMES.Year]: data,
            stepper,
            tabs,
            tabData,
            registrationNo
        }));
        // dispatch(setYear({ [STEP_NAMES.Year]: data }));
        break;
    case STEP_NAMES.Variant:
        dispatch(setVariant({ [STEP_NAMES.Variant]: data, registrationNo }));
        break;
    case STEP_NAMES.State:
        dispatch(setRegistrationState({ [STEP_NAMES.State]: data, registrationNo }));
        break;
    case STEP_NAMES.Branch:
        dispatch(setBranch({
            [STEP_NAMES.Branch]: data,
            stepper,
            tabs,
            tabData
        }));
        break;
    case STEP_NAMES.KilometerDriven:
        dispatch(setKilometerDriven(data));
        break;
    }
};

const resetLeadFormData = (clearToken = false) => ({
    type: Types.RESET_LEAD_FORM_DATA,
    clearToken
});

const setFlowName = (flowName) => ({ type: Types.SET_FLOW_NAME, flowName });

const updateCTALocation = (ctaLocation) => ({
    type: Types.UPDATE_CTA_LOCATION,
    ctaLocation
});

const setRTO = (rto) => ({
    type: Types.SET_RTO,
    rto
});

const updateProceedCTAStatus = (proceedCTA) => ({
    type: Types.UPDATE_PROCEED_CTA_STATUS,
    proceedCTA
});

const setFuelType = (carFuelType) => ({
    type: Types.SET_FUEL_TYPE,
    carFuelType
});

const setTransmissionType = (transmission) => ({
    type: Types.SET_TRANSMISSION_TYPE,
    transmission
});

const setVariantActiveScreen = (variantActiveScreen) => ({
    type: Types.SET_VARIANT_ACTIVE_SCREEN,
    variantActiveScreen
});
const setLeadMobile = (mobile) => ({
    type: Types.SET_MOBILE_ACTIVE_SCREEN,
    mobile
});

const setAllStepComplete = (allStepCompleted) => ({
    type: Types.SET_ALL_STEP_COMPLETE,
    allStepCompleted
});

const setLeadScore = (leadScore) => ({
    type: Types.SET_LEAD_SCORE,
    leadScore
});
const mergeNonSoLeadData = (response) => ({
    type: Types.MERGE_LEAD_DATA,
    data: response
});

const checkAppointmentExistence = (data) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.checkAppointmentExistence(data).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const getQuestionForUserIntent = () => () => {
    return new Promise((resolve, reject) => {
        VehicleService.getQuestionForUserIntent().then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const askUserIntent = (flag) => ({
    type: Types.ASK_USER_INTENT,
    flag
});

const setUserIntent = (intent) => ({
    type: Types.SET_USER_INTENT,
    intent
});

// eslint-disable-next-line no-unused-vars
const updateLeadWithGsDeny = (token, params) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.updateLeadWithGsDeny(token, params).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const verifyAuthUserLead = (data) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.verifyAuthUserLead(data).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const setUserCarRgistrationNo = (carRegistrationNo) => ({
    type: Types.SET_USER_CAR_REGISTRATION_NUMBER,
    carRegistrationNo
});

const setOwnerName = (ownerName) => ({
    type: Types.SET_OWNER_NAME,
    ownerName
});

const setAllStepFilled = (isAllStepFill) => ({
    type: Types.ALL_STEP_FILLED,
    isAllStepFill
});

const setShowWidget = (flag) => ({
    type: Types.SHOW_WIDGET,
    flag
});

const checkC2bGaExperiments = (payload) => () => {
    return new Promise((resolve, reject) => {
        VehicleService.checkC2bGaExperiments(payload).then((response) => {
            const expValue = response.data.detail.experimentValue;
            resolve(expValue);
        }).catch(error => {
            reject(error);
        });
    });
};

export {
    getQuestionForUserIntent,
    setAllStepComplete,
    setLeadMobile,
    setAllStepFilled,
    setData,
    setStep,
    setKilometerDriven,
    makeLead,
    showProceedButton,
    activateLastStep,
    updateLead,
    mergeLeadData,
    resetLeadFormData,
    setFlowName,
    setRTO,
    updateCTALocation,
    updateProceedCTAStatus,
    setFuelType,
    setTransmissionType,
    setVariantActiveScreen,
    setLeadScore,
    mergeNonSoLeadData,
    activateFirstStep,
    askUserIntent,
    setUserIntent,
    updateLeadWithGsDeny,
    verifyAuthUserLead,
    setOwnerName,
    setShowWidget,
    setUserCarRgistrationNo,
    checkAppointmentExistence,
    checkC2bGaExperiments
};
