import React, {Fragment} from "react";
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <Fragment>
            <div styleName={"styles.seoNav"}>
                <div className="container">
                    <ul>
                        <li className={"shimmer"}>&nbsp;</li>
                        <li className={"shimmer"}>&nbsp;</li>
                        <li className={"shimmer"}>&nbsp;</li>
                        <li className={"shimmer"}>&nbsp;</li>
                        <li className={"shimmer"}>&nbsp;</li>
                        <li className={"shimmer"}>&nbsp;</li>
                        <li className={"shimmer"}>&nbsp;</li>
                        <li className={"shimmer"}>&nbsp;</li>
                    </ul>
                </div>
            </div>
            <div className={"container mb-5"}>
                <div styleName={"styles.outer"}>
                    <div className="row">
                        <div className="col-8">
                            <div className="col-12 shimmer" styleName={"styles.shimmerBanner"}/>
                        </div>
                        <div className="col-4">
                            <div className="col-12 shimmer" styleName={"styles.shimmerHeading"}/>
                            <div className="col-12 shimmer" styleName={"styles.shimmerBanner"}/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Skeleton;
